<div class="footer">
    <div class="content row">
        <div class="footer-half">
            <div class="footer-column">
                <div class="underline first"></div>
                <div class="app-footer-icon">
                    <a (click)="linkPressed(main)">
                        <img loading="lazy"  [src]="themeService.getImageUrl('logo.png')" alt="">
                    </a>
                </div>
            </div>
            <div class="footer-column">
                <div class="underline"></div>
                <div>
                    <a (click)="linkPressed(pricing)" >{{'FOOTER.SECOND_COLUMN.PRICING' | translate}}</a>
                </div>
                <div>
                    <a (click)="linkPressed(faq)" >{{'FOOTER.SECOND_COLUMN.Q&A' | translate}}</a>
                </div>
                <div>
                    <a (click)="linkPressed(aboutUs)" >{{'FOOTER.SECOND_COLUMN.ABOUT_US' | translate}}</a>
                </div>
            </div>
        </div>
        <div class="footer-half">
            <div class="footer-column">
                <div class="underline"></div>
                <div>
                    <a (click)="linkPressed(privacyPolicy)">{{'FOOTER.THIRD_COLUMN.PRIVACY_POLICY' | translate}}</a>
                </div>
                <div>
                    <a (click)="linkPressed(tos)">{{'FOOTER.THIRD_COLUMN.TOS' | translate}}</a>
                </div>
                <div>
                    <a (click)="linkPressed(contactUs)">{{'FOOTER.THIRD_COLUMN.CONTACT_US' | translate}}</a>
                </div>
            </div>
            <div class="footer-column">
                <div class="underline"></div>
                <div><fa-icon [icon]="addressIcon"></fa-icon>  {{'FOOTER.FOURTH_COLUMN.ADDRESS_TITLE' | translate}}</div>
                <div [innerHTML]="'FOOTER.FOURTH_COLUMN.ADDRESS' | translate"></div>
                <div class="m-t-10"><fa-icon [icon]="phoneIcon"></fa-icon> {{'FOOTER.FOURTH_COLUMN.PHONE_TITLE' | translate}}</div>
                <div>{{'FOOTER.FOURTH_COLUMN.PHONE' | translate}}</div>
            </div>
        </div>
    </div>
</div>
