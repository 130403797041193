import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderWidgetComponent } from './slider-widget/slider-widget.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { InstagramComponent } from './instagram/instagram.component';
import { InstagramUserComponent } from './instagram-user/instagram-user.component';
import { InstagramPostComponent } from './instagram-post/instagram-post.component';
import { MatSliderModule } from '@angular/material/slider';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { PricingCardComponent } from './pricing-card/pricing-card.component';
import { PricingCardFreemiumComponent } from './pricing-card-freemium/pricing-card-freemium.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ReviewsComponent } from './reviews/reviews.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SafePipe } from '../pipes/safe.pipe';
import { SocialProofComponent } from './social-proof/social-proof.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ResetPasswordComponent } from './resetPassword/reset-password.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommentsSliderWidgetComponent } from './comments-slider-widget/comments-slider-widget.component';
import { CommentsPricingCardComponent } from './comments-pricing-card/comments-pricing-card.component';
import { SwiperModule } from 'swiper/angular';




@NgModule({
  declarations: [
    CommentsSliderWidgetComponent,
    CommentsPricingCardComponent,
    SliderWidgetComponent,
    HeaderComponent,
    FooterComponent,
    InstagramComponent,
    InstagramUserComponent,
    InstagramPostComponent,
    PricingCardComponent,
    PricingCardFreemiumComponent,
    ReviewsComponent,
    SafePipe,
    SocialProofComponent,
    ResetPasswordComponent,
    SafePipe,
  ],
  exports: [
    CommentsSliderWidgetComponent,
    CommentsPricingCardComponent,
    SliderWidgetComponent,
    HeaderComponent,
    FooterComponent,
    InstagramComponent,
    InstagramUserComponent,
    InstagramPostComponent,
    PricingCardComponent,
    PricingCardFreemiumComponent,
    ReviewsComponent,
    SocialProofComponent,
    ResetPasswordComponent,
    ReviewsComponent,

  ],
  imports: [
    CommonModule,
    MatSliderModule,
    FontAwesomeModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    HttpClientModule,
    AngularSvgIconModule,
    TranslateModule.forChild(),
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    SwiperModule,
  ]
})
export class ComponentsModule { }
