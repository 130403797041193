/* tslint:disable:max-line-length */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { PasswordErrorStateMatcher, UtilitiesService } from '../../services/utilities/utilities.service';
import { Plan } from '../../types/plan';
import { ProductAnalyticsEnum } from '../../enums/analytics/product.analytics.enum';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public error: string | null;
  public canSubmit = true;
  public errorMessage;
  public userNameText;
  public codeSent;
  public sendAgain;
  public verifyStep = false;
  public passwordMinLength = 6;
  @Output() resetPasswordStepEvent: EventEmitter<number> = new EventEmitter<number>();
  public matcher = new PasswordErrorStateMatcher();
  public form: FormGroup = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    code: new FormControl(''),
    passwordGroup: this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['']
    }, {validator: this.utilsService.validatePasswords})
  });
  private credentials: { userName: '', password: '' } = {
    userName: '',
    password: ''
  };

  constructor(private router: Router,
              private authService: AuthService,
              private utilsService: UtilitiesService,
              private translateService: TranslateService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.userNameText = 'RESET_PASSWORD.EMAIL';
  }

  getConfirmPasswordErrorMessage(): string {

    let error = '';

    if (this.form.get('passwordGroup').get('confirmPassword')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    this.translateService.get('COMMON.FORM.FORM_ERRORS.MATCHING_PASSWORDS').subscribe(translation => {
      error = translation;
    });

    return error;
  }

  getPasswordErrorMessage(): string {
    let error = '';

    if (this.form.get('passwordGroup').get('password')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    if (this.form.get('passwordGroup').get('password')?.hasError) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.PASSWORD_PATTERN', {minLength: this.passwordMinLength}).subscribe(translation => {
        error = translation;
      });
    } else {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.UNKNOWN', {field: 'password'}).subscribe(translation => {
        error = translation;
      });
    }

    return error;
  }

  public changeAuthType() {
    this.codeSent = false;
  }

  public async sendCodeManually() {
    this.sendAgain = true;
    this.sendCode();
  }

  public async sendCode() {
    try {
      this.errorMessage = '';
      this.codeSent = true;
      await this.authService.sendCode('email');
    } catch (error) {
      // TODO: handle error
    }
  }

  public async verifyCode() {
    this.errorMessage = '';
    try {
      await this.authService.providerConfirmPassword(this.credentials.userName, this.credentials.password, this.form.get('code').value);
      this.canSubmit = true;
      this.resetPasswordStepEvent.emit(4);
    } catch (error) {
      if (error && error.code === 'CodeMismatchException') {
        this.errorMessage = this.translateService.instant('MODALS.VERIFY_CODE.INVALID_ERROR');
      } else {
        this.errorMessage = this.translateService.instant('RESET_PASSWORD.GENERAL_ERROR');
      }
    }
  }

  async submit() {
    if (this.form.valid) {
      this.errorMessage = '';
      try {
        this.canSubmit = false;
        this.credentials.userName = this.form.get('userName').value.replace(/ /g, '');
        this.credentials.password = this.form.value.passwordGroup.password;
        await this.authService.providerForgotPassword(this.credentials.userName);
        await this.sendCode();
        this.verifyStep = true;
        this.resetPasswordStepEvent.emit(3);
      } catch (error) {
        if (error.code === 'LimitExceededException') {
          this.errorMessage = this.translateService.instant('RESET_PASSWORD.LIMIT_EXCEEDED_ERROR');
        } else {
          this.errorMessage = this.translateService.instant('RESET_PASSWORD.GENERAL_ERROR');
        }
        this.canSubmit = true;
      }
    }
  }
}
