<div class="instagram-likes-modal">
  <div class="heading">
    <div class="heading-buttons">
      <div class="close-button-container">
          <button mat-button (click)="closeModal()" class="close-modal">
            <fa-icon [icon]="closeIcon"></fa-icon>
          </button>
      </div>
    </div>
    <div class="modal-title bold" mat-dialog-title>{{modalTitle}}</div>
    <div class="modal-sub-title" mat-dialog-title >{{modalSubTitle}}</div>
  </div>
  <div mat-dialog-content>
      <app-instagram class="f-w"
        [ngClass]="{'overlay':paymentRouting}"
        (nextStep)="nextStep()"
        (searchingUser)="searchingUser($event)"
        (previousStep)="previousStep()"
        (selectedPostsChanged)="selectedPostsChanged($event)"
        (selectedUser)=setInstagramUser($event)
      >
      </app-instagram>
      <div class="checkbox-container" *ngIf="showTermsCheckbox">
        <mat-checkbox [checked]="agreedToTerms" (change)="termsChanged($event)" [disableRipple]="true" [required]="true">
          <span class="terms-checkbox">
            {{'COMMON.AGREE_TO_TERMS.I_AGREE' | translate}}
          <a class="text-underline" (click)="termsOfUseClicked()">{{'COMMON.AGREE_TO_TERMS.TERMS_LINK' | translate}}</a>
          {{themeConfigService?.themeConfig?.siteName}}</span>
        </mat-checkbox>
      </div>
    <div mat-dialog-actions>
      <button *ngIf="showCloseButton"  class="primary-button" mat-button cdkFocusInitial (click)="closeModal()">{{closeModalText}}</button>
      <button *ngIf="showPaymentButton" class="primary-button final-action-button payment-button" [ngClass]="{'disabled' : !canProceedToPayment()}" [disabled]="!canProceedToPayment()" mat-button cdkFocusInitial (click)="paymentPressed()"><fa-icon [icon]="creditCardIcon"></fa-icon>{{paymentText}}</button>
    </div>
    <mat-spinner *ngIf="paymentRouting" color="primary"></mat-spinner>
  </div>
</div>
