<div class="signup-modal">
  <div class="heading">
      <div class="heading-buttons">
          <div *ngIf="showFormStep" class="back-button-container" >
              <button mat-button  (click)="chooseUserStep()" class="back-button-modal"><fa-icon [icon]="backIcon"></fa-icon></button>
          </div>
          <div class="close-button-container">
              <button mat-button (click)="closeModal()" class="close-modal"><fa-icon [icon]="closeIcon"></fa-icon></button>
          </div>
      </div>
      <div class="modal-title bold" mat-dialog-title>{{stepTitle}}</div>
      <div class="modal-sub-title" mat-dialog-title *ngIf="stepSubTitle" >{{stepSubTitle}}</div>
      <div *ngIf="showFormStep" class="profile">
          <img *ngIf="imgSrc" [src]="imgSrc" alt="profile pic">
          <div class="user-name">@{{selectedInstagramUser.userName}}</div>
      </div>
  </div>
  <div *ngIf="showUsersStep" mat-dialog-content class="signup-users">
      <div class="signup-users-content">
        <app-instagram
          [includePostsStep]="false"
          (nextStep)="userSelected($event)"
        ></app-instagram>
      </div>
  </div>
  <div *ngIf="showFormStep" mat-dialog-content>
      <form  [ngClass]="{'overlay':showSpinner}" class="form" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
          <div class="form-content">
              <div class="input" (focus)="emailClicked()">
                  <mat-form-field class="signup-input">
                      <mat-label class="input-label">{{'COMMON.FORM.EMAIL' | translate}}<span class="required">*</span></mat-label>
                      <input autocomplete="off" formControlName="email" placeholder="ex: name@gmailcom" matInput>
                      <mat-hint>{{'COMMON.FORM.EMAIL_INFO' | translate}}</mat-hint>
                      <mat-error>{{getEmailErrorMessage()}}</mat-error>
                    </mat-form-field>
              </div>
              <div class="input" *ngIf="!data">
                  <mat-form-field class="signup-input">
                      <mat-label><span class="packages-instruction">{{'COMMON.FORM.SELECT_PACKAGE' | translate}}<span class="required">*</span></span></mat-label>
                      <mat-select formControlName="package">
                        <mat-option [disabled]="true">{{'COMMON.FORM.PACKAGE_DISABLED_OPTION' | translate}}</mat-option>
                        <mat-optgroup *ngFor="let formPlan of formPricingPlans" [label]="formPlan.planType">
                            <mat-option *ngFor="let plan of formPlan.plans" [value]="plan.id"><span class="select-option">{{plan.text}}</span></mat-option>
                        </mat-optgroup>
                      </mat-select>
                      <mat-error>{{getPackageErrorMessage()}}</mat-error>
                  </mat-form-field>
              </div>
            <mat-error>{{errorMessage}}</mat-error>
              <button (click)="signup()" [disabled]="showSpinner" type="submit" mat-button cdkFocusInitial class="primary-button final-action-button">{{'COMMON.FORM.SIGNUP' | translate}}</button>
          </div>
      </form>
  </div>
  <mat-spinner *ngIf="showSpinner" color="primary"></mat-spinner>
</div>
