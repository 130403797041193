<div >
      <mat-drawer-container class="example-container">
        <mat-drawer #drawer class="example-sidenav"  mode="over">
            <mat-nav-list>
                <a (click)="drawer.toggle()" href="" mat-list-item>Close</a>
                <a routerLink="/about-us">{{'HEADER.ABOUT_US' | translate}}</a>
                <a routerLink="/contact-us">{{'HEADER.CONTACT_US' | translate}}</a>
                <a routerLink="/buy-followers">{{'HEADER.BUY_FOLLOWERS' | translate}}</a>
                <a routerLink="/likes-for-existing-posts">{{'HEADER.EXISTING_POSTS' | translate}}</a>
                <a routerLink="/dashboard">{{'HEADER.DASHBOARD' | translate}}</a>
                <a routerLink="/login">{{'HEADER.LOGIN' | translate}}/{{'HEADER.LOGOUT' | translate}}</a>
            </mat-nav-list>
        </mat-drawer>

        <div class="example-sidenav-content" *ngIf="showDrawer">
          <button type="button" mat-button (click)="drawer.toggle()">
            Toggle sidenav
          </button>
        </div>

      </mat-drawer-container>
</div>
