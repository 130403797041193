<div class="pricing-card" [id]="isBestSeller()?'pricing-card-best-seller':''">
    <mat-card [ngClass]="pricingPlanGroup.name">
        <div class="plan-type">
            <span>{{pricingPlanGroup.name | translate}}</span>
            <img *ngIf="pricingPlanGroup.mostPopular" src="../../../assets/themes/localhost/images/most-popular.svg" alt="most-pupular">
        </div>
        <mat-card-content>
          <!-- <span class="best-seller" *ngIf="isBestSeller()">Best Seller</span> -->
            <div>
                <div class="pricing-titles ta-center">
                    <div class="comments-amount">
                        <span>{{pricingPlanGroup.min + '-' + pricingPlanGroup.max}}</span>
                        <span>{{'COMMENTS.PRICING_SECTION.PRICING_CARD.COMMENTS' | translate}}</span>
                    </div>
                    <div>{{'COMMENTS.PRICING_SECTION.PRICING_CARD.PER_POST' | translate}}</div>
                </div>
                <div class="divider"></div>
                <mat-list class="benefits-list" role="list">
                    <mat-list-item role="listitem" *ngFor="let feature of pricingPlanGroup.features; let i = index">
                        <span class="list-item">{{('COMMENTS.PRICING_SECTION.PRICING_CARD.FEATURES.'+ feature) | translate}}</span>
                        <img *ngIf="i === 0" class="blue-tick" src="../../../assets/themes/localhost/images/comments-blue-tick.svg" alt="">
                    </mat-list-item>
                </mat-list>
                <!-- <div class="divider"></div> -->
                <div class="ta-center">
                    <div class="price">
                        <span>{{'COMMENTS.PRICING_SECTION.PRICING_CARD.PRICE' | translate }}</span>
                        <span>{{getPrice()}}</span>
                    </div>
                    <div *ngIf="selectedPlanType === 'monthly'" class="bill">
                        {{'COMMENTS.PRICING_SECTION.PRICING_CARD.BILL_MONTHLY' | translate }}
                    </div>
                    <div *ngIf="selectedPlanType === 'yearly'" class="bill">
                        <span class="total">{{'COMMENTS.PRICING_SECTION.PRICING_CARD.BILL_TOTAL' | translate}}</span>
                        <span class="total">{{'COMMENTS.PRICING_SECTION.PRICING_CARD.PRICE' | translate}}{{pricingPlanGroup.totalPrice[selectedCommentsAmount]}}</span>
                        <span> / {{'COMMENTS.PRICING_SECTION.PRICING_CARD.BILL_YEARLY' | translate}}</span>
                    </div>
                    <button class="primary-button pricing-plan-button" mat-button (click)="subscribeToPlanClicked()">
                        {{'MAIN.PRICING_SECTION.PRICING_CARD.SUBSCRIBE_BUTTON' | translate}}
                    </button>
                    <div class="cancel-text m-t-10">{{'MAIN.PRICING_SECTION.PRICING_CARD.CANCEL' | translate}}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
