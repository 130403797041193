<!-- <app-header></app-header> -->

<div class="header" id="header">
  <mat-toolbar id="toolbar"
               [ngClass]="{'transparent-header' : isTransparentHeader, 'header-overlay' : showHeaderOverlay}"
               *ngIf="showHeaderFooter" color="primary" class="toolbar">
    <mat-toolbar-row>
      <div *ngIf="showServicesNotice" class="services-available">

        <div class="services-icon">
          <svg-icon [src]="themeService.getImageUrl('services-icon.svg')"></svg-icon>
        </div>
        <div class="services-text-and-date">
          <div class="services-text">{{'HEADER.SERVICES' | translate}}</div>
          <div class="services-date">{{currentDate | date: 'MM/dd/yyyy'}}</div>
        </div>
        <button mat-button (click)="closeServiceNotice()">
          <fa-icon [icon]="closeServicesNoticeIcon"></fa-icon>
        </button>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <div class="toolbar-content">
        <button class="side-nav-button" mat-icon-button (click)="sideNav.toggle()" fxShow="true" fxHide.gt-sm>
          <mat-icon>menu</mat-icon>
        </button>
        <span>
            <a mat-button>
              <img class="app-logo" (click)="logoClicked()" [src]="themeService.getImageUrl('logo.png')" alt="home">
            </a>
          </span>
        <div fxShow="true" fxHide.lt-md>
          <!-- The following menu items will be hidden on both SM and XS screen sizes -->
          <a class="side-nav-link" mat-button
             (click)="redirectToPage('comments')">
             {{'HEADER.REAL_COMMENTS' | translate}}
            <img src="../assets/themes/localhost/images/comments-blue-tick.svg" alt="">
          </a>
          <a class="side-nav-link" mat-button (click)="redirectToPage('about-us')">{{'HEADER.ABOUT_US' | translate}}</a>
          <a class="side-nav-link" mat-button
             (click)="redirectToPage('contact-us')"> {{'HEADER.CONTACT_US' | translate}}</a>
          <a class="side-nav-link" mat-button
             (click)="scrollToSection('Section7')">{{'HEADER.BUY_FOLLOWERS' | translate}}</a>
          <a class="side-nav-link" mat-button
             (click)="scrollToSection('Section5')">{{'HEADER.EXISTING_POSTS' | translate}}</a>
<!--          <a class="side-nav-link" mat-button-->
<!--             (click)="redirectToPage('comments')">{{'HEADER.REAL_COMMENTS' | translate}}</a>-->
          <a class="side-nav-link" mat-button *ngIf="authService.isAuth | async"
             [href]="themeService?.themeConfig?.dashboardUrl">{{'HEADER.DASHBOARD' | translate}}</a>
             <a class="side-nav-link" *ngIf="!(authService.isAuth | async)" mat-button
             (click)="redirectToPage('blog')">{{'HEADER.BLOG' | translate}}</a>
          <a class="side-nav-link" *ngIf="!(authService.isAuth | async)" mat-button
             (click)="openLogin()">{{'HEADER.LOGIN' | translate}}</a>

             <a class="side-nav-link" *ngIf="!(authService.isAuth | async)" mat-button
             (click)="openSignup()">{{'HEADER.SIGN_UP' | translate}}</a>

          

          <a class="side-nav-link" *ngIf="authService.isAuth | async" (click)="logout()"
             mat-button>{{'HEADER.LOGOUT' | translate}}</a>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container [ngStyle]="{'padding-top': showServicesNotice ? '55px' : '0'}">
    <mat-sidenav #sideNav>
      <mat-nav-list>
        <div class="sideNavTabs">
          <a mat-button (click)="redirectToPage('comments')">{{'HEADER.REAL_COMMENTS' | translate}}</a>
          <a mat-button (click)="redirectToPage('about-us')">{{'HEADER.ABOUT_US' | translate}}</a>
          <a mat-button (click)="redirectToPage('contact-us')">{{'HEADER.CONTACT_US' | translate}}</a>
          <a mat-button (click)="scrollToSection('Section7')">{{'HEADER.BUY_FOLLOWERS' | translate}}</a>
          <a mat-button (click)="scrollToSection('Section5')">{{'HEADER.EXISTING_POSTS' | translate}}</a>
          <a mat-button *ngIf="authService.isAuth | async"
             [href]="themeService?.themeConfig?.dashboardUrl">{{'HEADER.DASHBOARD' | translate}}</a>
             <a class="side-nav-link" mat-button
             (click)="redirectToPage('blog')">{{'HEADER.BLOG' | translate}}</a>
          <a *ngIf="!(authService.isAuth | async)" mat-button (click)="openLogin()">{{'HEADER.LOGIN' | translate}}</a>
          <a *ngIf="!(authService.isAuth | async)" mat-button
             (click)="openSignup()">{{'HEADER.SIGN_UP' | translate}}</a>
          <a *ngIf="authService.isAuth | async" (click)="logout()" mat-button>{{'HEADER.LOGOUT' | translate}}</a>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<div *ngIf="themeService.themeConfig.includeSocialProof" class="social-proof-placement">
  <app-social-proof></app-social-proof>
</div>
<app-footer></app-footer>
