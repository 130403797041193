import {Injectable} from '@angular/core';
import {Plan, PricingPlanGroup} from 'src/app/types/plan';
import {PlanTypeEnum} from '../../enums/planType.enum';
import {HttpClient} from '@angular/common/http';
import {ThemeConfigService} from '../theme-config/theme-config.service';
import {CommentPlans} from '../../types/comment-plans';
import {CommentPlanTypeEnum} from '../../enums/commentPlanType.enum';
import {map} from 'rxjs/operators';
import {SmmCategoryEnum} from "../../enums/smm-category.enum";

@Injectable({
  providedIn: 'root'
})
export class PlansService {
  private plans: Plan[];
  public commentPlans: CommentPlans[];
  public commentsSelectedPlanType: CommentPlanTypeEnum = CommentPlanTypeEnum.monthly;
  public buyCommentsAmount: string;
  public plansText: { id: string, text: string }[] = [];


  constructor(private httpClient: HttpClient, private themeConfigService: ThemeConfigService) {

  }

  private setPlansText(plans) {
    this.plansText = plans.map((plan: Plan) => {

      let planText;
      if (plan.smmCategory === SmmCategoryEnum.likes) {
        if (plan.planType === PlanTypeEnum.weekly) {
          planText = {
            id: plan.id,
            text: `${plan.minLikes} - ${plan.maxLikes} Likes for ${plan.price}$ per Week`
          };
        } else {
          if (plan.freemium) {
            planText = {
              id: plan.id,
              text: `${plan.minLikes} - ${plan.maxLikes} Likes for ${plan.price}$ per Month (7 day free trial)`
            };
          } else {
            planText = {
              id: plan.id,
              text: `${plan.minLikes} - ${plan.maxLikes} Likes for ${plan.price}$ per Month`
            };
          }
        }
      } else {
        if (plan.planType === PlanTypeEnum.yearly) {
          planText = {
            id: plan.id,
            text: `${plan.min} - ${plan.max} comments, ${plan.numberOfPosts} posts for ${plan.price}$ per year`
          };
        } else {
          planText = {
            id: plan.id,
            text: `${plan.min} - ${plan.max} comments, ${plan.numberOfPosts} posts for ${plan.price}$ per Month`
          };
        }
      }

      return planText;
    });
  }

  private groupByNameByType(plans: Plan[]): CommentPlans[] {
    const groupBy = {};
    const groupedByPlans = [];
    plans.forEach(plan => {
      groupBy[plan.name] = groupBy[plan.name] || {};
      groupBy[plan.name][plan.planType] = groupBy[plan.name][plan.planType] || [];
      groupBy[plan.name][plan.planType].push(plan);
    });
    Object.keys(groupBy).forEach(groupNameKey => {
      Object.keys(groupBy[groupNameKey]).forEach((groupPlanTypeKey) => {
        let commentPlan: CommentPlans;
        groupBy[groupNameKey][groupPlanTypeKey].forEach((plan, index) => {
          // @ts-ignore
          commentPlan = commentPlan || plan;
          commentPlan.innerPrice = commentPlan.innerPrice || {};
          commentPlan.innerPrice[(index + 1) * 10] = plan.price;
          commentPlan.price = commentPlan.innerPrice;
          commentPlan.ids = commentPlan.ids || {};
          commentPlan.ids[(index + 1) * 10] = plan.id;
          if (plan.totalPrice) {
            commentPlan.innerTotalPrice = commentPlan.innerTotalPrice || {};
            commentPlan.innerTotalPrice[(index + 1) * 10] = plan.totalPrice;
            commentPlan.totalPrice = commentPlan.innerTotalPrice;
          }
        });
        groupedByPlans.push(commentPlan);
      });
    });
    return groupedByPlans;
  }

  async getVerifiedCommentPlans(): Promise<CommentPlans[]> {
    return this.httpClient.get<Plan[]>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getVerifiedCommentPlans`)
      .pipe(map(results => {
        return this.groupByNameByType(results);
      })).toPromise();
  }

  async getPricingPlanById(id: string) {
    if (this.plans) {
      return this.plans.find(plan => plan.id === id);
    }
    return this.httpClient.get<Plan>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getPlan?id=${id}`)
      .toPromise();
  }

  getPricingPlanTextById(plans, id: string) {
    if (!this.plansText?.length) {
      this.setPlansText(plans);
    }
    return this.plansText.find(plan => plan.id === id);
  }

  async getPricingPlans(): Promise<Plan[]> {
    return this.httpClient.get<Plan[]>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getPlans`).toPromise();
  }

  async getAllPlans(): Promise<Plan[]> {
    const likesPlans = this.httpClient.get<Plan[]>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getPlans`).toPromise();
    const commentsPlans = this.httpClient.get<Plan[]>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getVerifiedCommentPlans`).toPromise();
    return Promise.all([likesPlans, commentsPlans]).then(result => {
      return [...result[0], ...result[1]];
    });
  }

  async getFreemiumPricingPlans(): Promise<Plan[]> {
    return this.httpClient.get<Plan[]>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getFreemiumPlans`).toPromise();
  }

  async getPricingPlanGroups(): Promise<PricingPlanGroup[]> {
    let pricingPlanGroups: PricingPlanGroup[] = [];
    this.plans = await this.getPricingPlans();
    const planMap: any = {};
    this.setPlansText(this.plans);
    this.plans.forEach((plan) => {
      planMap[plan.parentId] = planMap[plan.parentId] || {};
      planMap[plan.parentId][plan.planType === PlanTypeEnum.weekly ? 'weeklyPlan' : 'monthlyPlan'] = plan;
    });
    pricingPlanGroups = Object.values(planMap);

    return pricingPlanGroups;
  }

  async getFreemiumPricingPlanGroups(): Promise<PricingPlanGroup[]> {
    let pricingPlanGroups: PricingPlanGroup[] = [];
    this.plans = await this.getFreemiumPricingPlans();
    const planMap: any = {};
    this.setPlansText(this.plans);
    this.plans.forEach((plan) => {
      planMap[plan.id] = planMap[plan.parentId] || {};
      planMap[plan.id][plan.planType === PlanTypeEnum.weekly ? 'weeklyPlan' : 'monthlyPlan'] = plan;
    });
    pricingPlanGroups = Object.values(planMap);

    return pricingPlanGroups;
  }

  public getPackageName(plan: Plan) {
    if (plan) {
      return `${plan.minLikes}-${plan.maxLikes}`;
    }
    return '';
  }
  public getCommentPackageName(plan: CommentPlans) {
    if (plan) {
      return `${plan.max}-${plan.min}`;
    }
    return '';
  }
  getWeeklyPricingPlansText(plans: Plan[]): { id: string, text: string }[] {
    const res: { id: string, text: string }[] = [];
    plans.forEach((plan) => {
      if (plan.planType === PlanTypeEnum.weekly) {
        res.push(this.getPricingPlanTextById(plans, plan.id));
      }
    });

    return res;
  }

  getMonthlyPricingPlansText(plans: Plan[]): { id: string, text: string }[] {
    const res: { id: string, text: string }[] = [];
    plans.forEach((plan) => {
      if (plan.planType === PlanTypeEnum.monthly) {
        res.push(this.getPricingPlanTextById(plans, plan.id));
      }
    });

    return res;
  }
  getYearlyPricingPlansText(plans: Plan[]): { id: string, text: string }[] {
    const res: { id: string, text: string }[] = [];
    plans.forEach((plan) => {
      if (plan.planType === PlanTypeEnum.yearly) {
        res.push(this.getPricingPlanTextById(plans, plan.id));
      }
    });

    return res;
  }
}
