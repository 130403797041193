// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dashboardUrl: 'http://localhost:4300',
  hotJar: '',
  backendUrl: 'https://dev-api.insta-likes.com',
  tagManager: '',
  localhost_tagManager: '',
  'local.insta-likes.com_tagManager': '',
  'dev.insta-likes.com_tagManager': '',
  locationApi: 'http://ip-api.com/json/',
  randomUserApi: 'https://randomuser.me/api/?nat=',
  firebase: {
    apiKey: 'AIzaSyAiIsdXRgCQU-pnGI8A5ncECZFsPTsDCsQ',
    authDomain: 'digitalboost-dev.firebaseapp.com',
    projectId: 'digitalboost-dev',
    storageBucket: 'digitalboost-dev.appspot.com',
    messagingSenderId: '915306870495',
    appId: '1:915306870495:web:4c90414fc906d8566d5b6a',
    measurementId: 'G-LW9V675VBV'
  },
  cmsBearer:
  '54a2eb48df7a47fb6b49cca9d64026d319a6a3c6c7b775d67b744fc649181f40e5df458a5d023da200330dd719c6967493dcef1f35d593d6f255e6e4b265273cf9f74c393f170f40688c0feac8288f0e78fe258e0b503e5dfeaef2cd6b0409a159ed3101ea1e9de3ddf244ae61033d6ee4f625e0343ed3519738cb237b9865f0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
