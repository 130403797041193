import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ThemeConfigService } from '../theme-config/theme-config.service';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {
  constructor(private httpService: HttpClient, private themeConfigService: ThemeConfigService) {
  }

  public getImageByUrl(imageUrl: string): Promise<any> {
    return this.httpService.post(`${this.themeConfigService.themeConfig.backendUrl}/instagram/getImage?`, {
      url: imageUrl
    }).toPromise();
  }

  public findAccounts(userName: string): Promise<any> {
    return this.httpService.post(`${this.themeConfigService.themeConfig.backendUrl}/instagram/proxy`, {
      keyword: userName
    }).toPromise();
  }

  public getAccountPosts(userId: string): Promise<any> {
    return this.httpService.post(`${this.themeConfigService.themeConfig.backendUrl}/instagram/posts`, {
      userId
    }).toPromise();
  }

  public getUserProfile(userName: string): Promise<any> {
    return this.httpService.post(`${this.themeConfigService.themeConfig.backendUrl}/instagram/profile`, {
      userName
    }).toPromise();
  }
}
