import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { ThemeConfigService } from '../../services/theme-config/theme-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public aboutUs = 'about-us';
  public contactUs = 'contact-us';
  public tos = 'terms-of-use';
  public pricing = 'pricing';
  public faq = 'faq';
  public privacyPolicy = 'privacy-policy';
  public main = '';

  addressIcon = faMapMarkerAlt;
  phoneIcon = faPhoneAlt;

  constructor(private router: Router,
              public themeService: ThemeConfigService,
              private utilService: UtilitiesService) {
  }

  ngOnInit(): void {
  }

  linkPressed(link: string) {
    if (link === this.pricing) {
      if (this.router.url !== '/' && this.router.url !== '/freemium') {
        const route = localStorage.getItem('freemium') ? 'freemium' : '';
        this.router.navigate([route]).then(() => {
          setTimeout(() => {
            this.utilService.scrollToElement('pricing-card-best-seller');
          }, 500);
        });
      } else {
        this.utilService.scrollToElement('pricing-card-best-seller');
      }

    } else if (link === this.faq) {
      if (this.router.url !== '/' && this.router.url !== '/freemium') {
        const route = localStorage.getItem('freemium') ? 'freemium' : '';
        this.router.navigate([route]).then(() => {
          setTimeout(() => {
            this.utilService.scrollToElement('Section8');
          }, 500);
        });
      } else {
        this.utilService.scrollToElement('Section8');
      }

    } else {

      this.router.navigate(['/' + link]).then(() => {
        setTimeout(() => {
          this.utilService.scrollToTop();
        }, 0);
      });
    }

  }

}
