import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Plan, PricingPlanGroup } from 'src/app/types/plan';
import { faCheckCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { PlanTypeEnum } from '../../enums/planType.enum';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ProductAnalyticsEnum } from '../../enums/analytics/product.analytics.enum';
import { PlansService } from '../../services/pricing-plans/pricing-plans.service';


@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss']
})
export class PricingCardComponent implements OnInit {

  @Output() onSelectedPricingPlan: EventEmitter<Plan>;

  @Input() pricingPlanGroup: PricingPlanGroup;

  public planTypeSelected: PlanTypeEnum = PlanTypeEnum.weekly;
  public subscribeIcon = faChevronRight;
  checkIcon = faCheckCircle;

  constructor(private analyticsService: AnalyticsService, private plansService: PlansService) {
    this.onSelectedPricingPlan = new EventEmitter<Plan>();
  }

  ngOnInit(): void {
  }

  changeSelectedPlan(planType: PlanTypeEnum) {
    this.planTypeSelected = planType;
  }

  subscribeToPlanClicked() {
    if (this.isPlanWeekly()) {
      this.analyticsService.subscribeClick(this.pricingPlanGroup.weeklyPlan.price,
        this.plansService.getPackageName(this.pricingPlanGroup.weeklyPlan), ProductAnalyticsEnum.likesSubscription, PlanTypeEnum.weekly);
      this.onSelectedPricingPlan.emit(this.pricingPlanGroup.weeklyPlan);
    } else {
      this.analyticsService.subscribeClick(this.pricingPlanGroup.monthlyPlan.price,
        this.plansService.getPackageName(this.pricingPlanGroup.monthlyPlan), ProductAnalyticsEnum.likesSubscription, PlanTypeEnum.monthly);
      this.onSelectedPricingPlan.emit(this.pricingPlanGroup.monthlyPlan);
    }
  }

  isPlanWeekly(): boolean {
    return this.planTypeSelected === PlanTypeEnum.weekly;
  }

  isPlanMonthly(): boolean {
    return this.planTypeSelected === PlanTypeEnum.monthly;
  }

  changeToWeekly() {
    this.planTypeSelected = PlanTypeEnum.weekly;
    this.analyticsService.packageToggle(PlanTypeEnum.weekly, this.pricingPlanGroup.weeklyPlan.price,
      this.plansService.getPackageName(this.pricingPlanGroup.weeklyPlan), ProductAnalyticsEnum.likesSubscription);
  }

  changeToMonthly() {
    this.planTypeSelected = PlanTypeEnum.monthly;
    this.analyticsService.packageToggle(PlanTypeEnum.monthly, this.pricingPlanGroup.monthlyPlan.price,
      this.plansService.getPackageName(this.pricingPlanGroup.monthlyPlan), ProductAnalyticsEnum.likesSubscription);
  }

  getMinLikes() {
    return (this.planTypeSelected === PlanTypeEnum.weekly) ?
      this.pricingPlanGroup.weeklyPlan.minLikes : this.pricingPlanGroup.monthlyPlan.minLikes;
  }

  getMaxLikes() {
    return (this.planTypeSelected === PlanTypeEnum.weekly) ?
      this.pricingPlanGroup.weeklyPlan.maxLikes : this.pricingPlanGroup.monthlyPlan.maxLikes;
  }


  getPrice() {
    return (this.planTypeSelected === PlanTypeEnum.weekly) ?
      this.pricingPlanGroup.weeklyPlan.price : this.pricingPlanGroup.monthlyPlan.price;
  }
  isBestSeller() {
    return (this.planTypeSelected === PlanTypeEnum.weekly) ?
      this.pricingPlanGroup.weeklyPlan.bestSeller : this.pricingPlanGroup.monthlyPlan.bestSeller;
  }
}
