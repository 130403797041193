import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public showDrawer:boolean;

  constructor() {
    if(window.innerWidth > 1000){
      this.showDrawer = true;
    }else{
      this.showDrawer = true;
    }
  }

  ngOnInit(): void {
  }

}
