import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstagramFollowersModalComponent } from './instagram-followers-modal/instagram-followers-modal.component';
import { SignupModalComponent } from './signup-modal/signup-modal.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentsModule } from '../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { InstagramLikeModalComponent } from './instagram-likes-modal/instagram-likes-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    SignupModalComponent,
    InstagramLikeModalComponent,
    InstagramFollowersModalComponent,
    LoginModalComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FontAwesomeModule,
    ComponentsModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatProgressBarModule,
    TranslateModule.forChild(),
    FormsModule
  ],
  providers: [{
    provide: MatDialogRef,
    useValue: {}
  }]
})
export class ModalsModule {
}
