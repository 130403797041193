import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InstagramService } from 'src/app/services/instagram/instagram.service';
import { InstagramDialogData } from 'src/app/types/instagram-dialog-data';
import { faArrowLeft, faCreditCard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { InstagramPost } from 'src/app/types/instagram-post';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { InstagramUserData } from 'src/app/types/instagram-user-data';
import { PaymentService } from '../../services/payment/payment.service';
import { ClientPaymentRequest } from '../../types/client-payment-request';
import { CoinTypeEnum } from '../../enums/coinType.enum';
import { LanguageTypeEnum } from '../../enums/languageType.enum';
import { PaymentTypeEnum } from '../../enums/paymentType.enum';
import { AuthService } from '../../services/auth/auth.service';
import { ProductTypeEnum } from '../../enums/productType.enum';
import { ThemeConfigService } from '../../services/theme-config/theme-config.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ProductAnalyticsEnum } from '../../enums/analytics/product.analytics.enum';
import { PlanTypeEnum } from '../../enums/planType.enum';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Component({
  selector: 'app-instagram-likes-modal',
  templateUrl: './instagram-likes-modal.component.html',
  styleUrls: ['./instagram-likes-modal.component.scss']
})
export class InstagramLikeModalComponent implements OnInit {

  public modalTitle: string = '';
  public modalSubTitle: string = '';
  public closeModalText: string = '';
  public paymentText: string = '';
  public steps: string[] = ['getUsers', 'getPost'];
  public currentStep: string = this.steps[0];
  public numberOfSelectedPosts: number = 0;
  public totalPrice: number = 0;
  public totalLikesPurchased: number = 0;
  public paymentRouting = false;
  private selectedInstagramUser: InstagramUserData;

  public showCloseButton: boolean = true;
  public showBackButton: boolean = false;
  public showPaymentButton: boolean = false;
  public showTermsCheckbox: boolean = false;
  public agreedToTerms: boolean = false;

  public creditCardIcon = faCreditCard;
  public backIcon = faArrowLeft;
  public closeIcon = faTimes;

  private selectedPosts: InstagramPost[] = [];

  constructor(
    public dialogRef: MatDialogRef<InstagramLikeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InstagramDialogData,
    private instagramService: InstagramService,
    public themeConfigService: ThemeConfigService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private utilService: UtilitiesService,
    private paymentService: PaymentService,
    private translateService: TranslateService) {

    this.modalTitle = this.data.title;

    this.setModalSubTitle();
    this.translateService.get('MODALS.INSTAGRAM_LIKES.SELECT_USER_STEP.CLOSE_BUTTON').subscribe(translation => {
      this.closeModalText = translation;
    });
    this.translateService.get('MODALS.INSTAGRAM_LIKES.SELECT_POSTS_STEP.PAYMENT_BUTTON').subscribe(translation => {
      this.paymentText = translation;
    });

  }

  ngOnInit(): void {
  }

  async closeModal(data?): Promise<any> {
    this.dialogRef.close(data);
  }

  nextStep() {

    if (this.currentStep === this.steps[0]) {

      this.currentStep = this.steps[1];
      this.showCloseButton = false;
      this.showBackButton = true;
      this.showTermsCheckbox = true;
      this.showPaymentButton = true;

    } else if (this.currentStep === this.steps[1]) {
      this.currentStep = this.steps[2];
      this.showPaymentButton = false;
      this.showTermsCheckbox = false;
    }
  }

  searchingUser(queryText) {
    const price = this.data.likesPricePerPost * this.numberOfSelectedPosts;
    this.analyticsService.searchInstagramUser(price, ProductAnalyticsEnum.singleLikes, queryText);
  }

  previousStep() {

    if (this.currentStep === this.steps[1]) {
      this.currentStep = this.steps[0];
      this.showPaymentButton = false;
      this.showTermsCheckbox = false;
      this.showCloseButton = true;
      this.showBackButton = false;
    }
  }

  selectedPostsChanged(selectedPosts: InstagramPost[]) {
    const numberOfPosts = selectedPosts.length;
    this.numberOfSelectedPosts = numberOfPosts;
    this.totalPrice = this.data.likesPricePerPost * numberOfPosts;
    this.totalLikesPurchased = this.data.likesAmountPerPost * numberOfPosts;
    this.selectedPosts = selectedPosts;
    this.setModalSubTitle();
    const postLinks = this.getPostLinks();
    this.analyticsService.selectInstagramPost(this.totalPrice, ProductAnalyticsEnum.singleLikes,
      this.selectedInstagramUser.userName, postLinks);
  }
  setModalSubTitle() {

    if (this.data.likesAmountPerPost && this.data.likesPricePerPost) {
      if (this.numberOfSelectedPosts) {
        this.translateService.get('MODALS.INSTAGRAM_LIKES.SELECT_POSTS_STEP.SUB_TITLE').subscribe(translation => {
          this.modalSubTitle = `${this.data.likesAmountPerPost * this.numberOfSelectedPosts} ${translation} ${(this.data.likesPricePerPost * this.numberOfSelectedPosts).toFixed(1)}$`;
        });
      } else {
        this.translateService.get('MODALS.INSTAGRAM_LIKES.SELECT_POSTS_STEP.SUB_TITLE').subscribe(translation => {
          this.modalSubTitle = `${this.data.likesAmountPerPost} ${translation} ${this.data.likesPricePerPost}$`;
        });
      }
    }
  }

  termsChanged(event: MatCheckboxChange) {
    this.agreedToTerms = event.checked;
  }

  setInstagramUser(instagramUser: InstagramUserData) {
    const price = this.data.likesPricePerPost * this.numberOfSelectedPosts;
    this.analyticsService.selectInstagramUser(price, ProductAnalyticsEnum.singleLikes, instagramUser.userName);
    this.selectedInstagramUser = instagramUser;
  }

  async paymentPressed() {
    const authClient = await this.authService.getCurrentUser();
    const anonymousClient = this.authService.getAnonymousClient();
    this.paymentRouting = true; //
    const postLinks = this.getPostLinks();
    const paymentRequest: ClientPaymentRequest =
      {
        uid: authClient ? authClient.getUsername() : anonymousClient?.uid,
        socialUserId: this.selectedInstagramUser.userName,
        coinID: CoinTypeEnum.USD,
        products: [{
          id: '1',
          type: ProductTypeEnum.likes,
          name: this.modalSubTitle,
          paymentType: PaymentTypeEnum.single
        }],
        language: LanguageTypeEnum.english,
        links: postLinks,
        amount: this.data.likesAmountPerPost,
        selectedItemsCount: this.numberOfSelectedPosts,
        isAnonymous: !authClient
      };
    const results = await this.paymentService.charge(paymentRequest);
    if (results.success) {
      const price = this.data.likesPricePerPost * this.numberOfSelectedPosts;
      const paymentData = {
        url: results.value,
        conversionValue: price,
        product: ProductAnalyticsEnum.singleLikes,
        userName: this.selectedInstagramUser.userName
      };
      this.paymentService.storePayment(paymentData);
      this.router.navigate(['payment/process']).then(() => {
        this.closeModal(results.value);
      });
    } else {
      this.paymentRouting = false;
      this.paymentService.deletePayment();
      // TODO: error handling
    }
  }

  termsOfUseClicked() {
    this.router.navigate(['terms-of-use']).then(() => {
      this.closeModal();
    });
  }

  canProceedToPayment(): boolean {
    return !this.paymentRouting && this.agreedToTerms && (this.numberOfSelectedPosts > 0);
  }

  private getPostLinks() {
    return this.selectedPosts ? this.selectedPosts.map(post => {
      return `https://www.instagram.com/p/${post.shortcode}`;
    }) : [];
  }

}
