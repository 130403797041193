<div class="instagram-content">
    <div class="step">
        <div class="search-container">
            <input autocomplete="off" class="search-bar" #usersSearchInput matInput spellcheck="false" placeholder="username">
        </div>
        <div *ngIf="showProgressBar" class="progress-bar">
            <mat-progress-bar value={{progressBarValue}} ></mat-progress-bar>
            <div class="progress-description">{{progressDescription}}</div>
        </div>
        <div *ngIf="showNoUsersFoundMessage || showNoPostsFoundMessage" class="not-found-errors">
            <div *ngIf="showNoUsersFoundMessage" class="not-found-message">
                We could not find the user, please try again.
            </div>
            <div *ngIf="showNoPostsFoundMessage" class="not-found-message">
                We could not find  your posts, your account might be private.
            </div>
        </div>
        <div class="search-results" *ngIf="showUserResults">
            <app-instagram-user *ngFor="let user of usersResults" [userData]="user" (click)="onSelectedUser(user)"></app-instagram-user>
        </div>
        <div class="posts"  *ngIf="showUserPosts">
            <div class="user-posts">
                <div class="post" *ngFor="let post of userPosts" (click)="selectedPost(post)">
                    <app-instagram-post  [postData]="post"></app-instagram-post>
                    <span *ngIf="post.selected" class="selected-post"><fa-icon [icon]="heartIcon" [styles]="{'color': '#65ff65'}"></fa-icon></span>
                </div>
            </div>
        </div>
    </div>
</div>

