import { APP_INITIALIZER, NgModule } from '@angular/core';
import {  TransferState } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { ModalsModule } from './modals/modals.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { translateBrowserLoaderFactory } from './services/translation/translate-browser.loader';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { ThemeConfigService } from './services/theme-config/theme-config.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import {DatePipe} from '@angular/common';

export function configFactory(config: ThemeConfigService) {
  return () => config.load();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    MatButtonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ModalsModule,
    ComponentsModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    FontAwesomeModule,
    AngularSvgIconModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    GoogleTagManagerModule.forRoot({
      id: environment[`${window.location.hostname.toLowerCase()}_tagManager`],
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [ThemeConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ThemeConfigService],
      multi: true
    },
    DatePipe,
    TransferState]
})
export class AppModule {
}
