import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PlanTypeEnum } from '../../enums/planType.enum';
import { ProductAnalyticsEnum } from '../../enums/analytics/product.analytics.enum';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private gtmService: GoogleTagManagerService) {
    this.gtmService.addGtmToDom();
  }

  public async packageToggle(event: PlanTypeEnum, conversionValue: number, packageName: string, product: ProductAnalyticsEnum) {
    const gtmTag = {
      event,
      product,
      package: packageName,
      conversionValue,
    };
    await this.pushTag(gtmTag);
  }

  public async subscribeClick(conversionValue: number, packageName: string, product: ProductAnalyticsEnum, renewable: PlanTypeEnum) {
    const gtmTag = {
      event: 'click_subscribe',
      product,
      package: packageName,
      conversionValue,
      renewable
    };
    await this.pushTag(gtmTag);
  }

  public async searchInstagramUser(conversionValue: number, product: ProductAnalyticsEnum,
                                   userName: string, renewable?: PlanTypeEnum, packageName?: string) {
    const gtmTag: any = {
      event: 'click_user_search',
      product,
      conversionValue,
    };
    if (product === ProductAnalyticsEnum.likesSubscription) {
      gtmTag.package = packageName;
      gtmTag.renewable = renewable;
    }
    await this.pushTag(gtmTag);
  }

  public async selectInstagramUser(conversionValue: number,
                                   product: ProductAnalyticsEnum, userName: string, renewable?: PlanTypeEnum, packageName?: string) {
    const gtmTag: any = {
      event: 'click_user_pick',
      product,
      user_name: userName,
      conversionValue,
    };
    if (renewable && product === ProductAnalyticsEnum.likesSubscription) {
      gtmTag.package = packageName;
      gtmTag.renewable = renewable;
    }
    await this.pushTag(gtmTag);
  }

  public async selectInstagramPost(conversionValue: number,
                                   product: ProductAnalyticsEnum, userName: string, postLinks: string[]) {
    const gtmTag: any = {
      event: 'click_post_pick',
      post_links: postLinks?.join(','),
      user_name: userName,
      conversionValue,
    };
    await this.pushTag(gtmTag);
  }

  public async emailFieldClick(conversionValue: number, packageName: string,
                               product: ProductAnalyticsEnum, renewable: PlanTypeEnum, userName: string) {
    const gtmTag = {
      event: 'click_email',
      product,
      user_name: userName,
      package: packageName,
      conversionValue,
      renewable
    };
    await this.pushTag(gtmTag);
  }

  public async passwordFieldClick(conversionValue: number, packageName: string,
                                  product: ProductAnalyticsEnum, renewable: PlanTypeEnum, userName: string) {
    const gtmTag = {
      event: 'click_password',
      product,
      user_name: userName,
      package: packageName,
      conversionValue,
      renewable
    };
    await this.pushTag(gtmTag);
  }

  public async signupFail(conversionValue: number, product: ProductAnalyticsEnum, userName: string,
                          renewable: PlanTypeEnum, packageName: string, reason: string) {
    const gtmTag = {
      event: 'signup_fail',
      product,
      user_name: userName,
      reason,
      package: packageName,
      conversionValue,
      renewable
    };
    await this.pushTag(gtmTag);
  }

  public async signupSuccsess(conversionValue: number, product: ProductAnalyticsEnum, userName: string,
                              renewable: PlanTypeEnum, packageName: string) {
    const gtmTag = {
      event: 'signup_successfully',
      product,
      user_name: userName,
      package: packageName,
      conversionValue,
      renewable
    };
    await this.pushTag(gtmTag);
  }

  public async purchase(conversionValue: number, product: ProductAnalyticsEnum,
                        userName: string, packageName: string, renewable?: PlanTypeEnum) {
    const gtmTag: any = {
      event: 'purchaseComplete',
      product,
      user_name: userName,
      conversionValue,
    };
    if (renewable && product === ProductAnalyticsEnum.likesSubscription) {
      gtmTag.package = packageName;
      gtmTag.renewable = renewable;
    }
    await this.pushTag(gtmTag);
  }

  public async purchaseFail(conversionValue: number, product: ProductAnalyticsEnum,
                            userName: string, packageName: string, renewable?: PlanTypeEnum, reason?: string) {
    const gtmTag: any = {
      event: 'purchase_fail',
      product,
      user_name: userName,
      conversionValue,
      reason,
    };
    if (renewable && product === ProductAnalyticsEnum.likesSubscription) {
      gtmTag.package = packageName;
      gtmTag.renewable = renewable;
    }
    await this.pushTag(gtmTag);
  }

  private async pushTag(gtmTag: any) {
    try {
      if (environment.production) {
        await this.gtmService.pushTag(gtmTag);
     } else {
       console.log(gtmTag);
     }
    } catch (e) {
      // add error handler
    }
  }
}
