import {Component, HostListener, Inject, OnInit, Optional, PLATFORM_ID, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LoginModalComponent} from './modals/login-modal/login-modal.component';
import {UtilitiesService} from './services/utilities/utilities.service';
import {Amplify} from 'aws-amplify';
import {environment} from '../environments/environment';
import {SignupModalComponent} from './modals/signup-modal/signup-modal.component';
import {AuthService} from './services/auth/auth.service';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {DeviceDetectorService} from 'ngx-device-detector';
import {isPlatformBrowser} from '@angular/common';
import {EventService} from './services/event/event.service';
import {ThemeConfigService} from './services/theme-config/theme-config.service';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('sideNav') sideNav!: MatSidenav;

  public showHeaderFooter = true;
  public isTransparentHeader: boolean;
  public showHeaderOverlay: boolean;
  public showServicesNotice: boolean;
  public showHeaderOverlayInOtherPages: boolean;
  public closeServicesNoticeIcon = faTimes;
  public currentDate: number;
  public isFreemium: boolean;
  private modalOpen: boolean;

  constructor(translate: TranslateService,
              private router: Router,
              private utilService: UtilitiesService,
              private route: ActivatedRoute,
              public authService: AuthService,
              public themeService: ThemeConfigService,
              private eventService: EventService,
              @Inject(PLATFORM_ID) private platformId: any,
              @Optional() @Inject(REQUEST) protected request: Request,
              private deviceDetector: DeviceDetectorService,
              public dialog: MatDialog) {
    if (!isPlatformBrowser(platformId)) {
      this.deviceDetector.setDeviceInfo(request.headers['user-agent']);
    }
    Amplify.configure(this.themeService.themeConfig.awsConfig);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

  }

  ngOnInit() {
    this.isTransparentHeader = this.themeService.themeConfig.transparentHeader;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.isTransparentHeader) {
        this.showHeaderOverlayInOtherPages = this.router.url !== '/' && this.router.url !== '/freemium';
        this.showHeaderOverlay = this.showHeaderOverlayInOtherPages;
      }
    });
    this.route.queryParams.subscribe((params: Params) => {
      if (params.type) {
        if (params.type === 'freemium') {
          this.isFreemium = true;
          localStorage.setItem('freemium', '1');
        }
      }
      this.utilService.setStore('query', params);
      this.utilService.setStore('queryString', window.location?.search?.substr(1) || '');
    });
    this.eventService.getModalStatus().subscribe((modalState) => {
      this.modalOpen = modalState;
    });
    this.currentDate = Date.now();
    this.showServicesNotice = this.shouldShowServicesNotice();
  }

  shouldShowServicesNotice(): boolean {
    const noticeClosedDate = localStorage.getItem('socialMediaServicesDate');
    if (noticeClosedDate) {
      return this.utilService.dayHasPassed(this.currentDate, Number(noticeClosedDate));
    } else {
      return true;
    }
  }

  closeServiceNotice() {
    this.showServicesNotice = false;
    localStorage.setItem('socialMediaServicesDate', this.currentDate.toString());
  }

  openLogin(resetPassword = false) {
    this.dialog.open(LoginModalComponent, {
      hasBackdrop: false, data: {
        resetPassword
      }
    });
  }

  openSignup() {
    this.dialog.open(SignupModalComponent, {hasBackdrop: false});
  }

  async logout() {
    await this.authService.logout();
    //  this.isAuth = false;
  }

  redirectToPage(pageRoute: string) {
    this.router.navigate([pageRoute]).then(() => {
      setTimeout(() => {
        this.utilService.scrollToTop();
      }, 0);

      if (this.sideNav.opened) {
        this.sideNav.close();
      }
    });
  }

  scrollToSection(elementId: string): void {

    if (this.router.url !== '/' && this.router.url !== '/freemium') {
      const route = this.isFreemium ? 'freemium' : '';
      this.router.navigate([route]).then(() => {
        setTimeout(() => {
          this.utilService.scrollToElement(elementId);
        }, 500); // TODO: find a better solution
      });
    } else {
      this.utilService.scrollToElement(elementId);
    }

    if (this.sideNav.opened) {
      this.sideNav.close();
    }
  }


  logoClicked() {
    const route = this.isFreemium ? 'freemium' : '';
    this.router.navigate([route]).then(() => {
      setTimeout(() => {
        this.utilService.scrollToTop();
      }, 0);

      if (this.sideNav.opened) {
        this.sideNav.close();
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  styleHeader(event) {
    if (this.isTransparentHeader && !this.showHeaderOverlayInOtherPages) {
      this.showHeaderOverlay = (window.pageYOffset > 90) || this.modalOpen;
    }
  }
}
