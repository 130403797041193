import {Component, OnInit, HostListener} from '@angular/core';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {SocialProofService} from 'src/app/services/social-proof/social-proof.service';
import {SocialProofData} from 'src/app/types/social-proof-data';

@Component({
  selector: 'app-social-proof',
  templateUrl: './social-proof.component.html',
  styleUrls: ['./social-proof.component.scss']
})
export class SocialProofComponent implements OnInit {

  public socialProof: SocialProofData;
  public checkIcon = faCheckCircle;
  public showSocialProof: boolean = false;
  private hideProofInterval: number;
  public popupCounter: number = 0;
  public mobileScreenSize: boolean = false;

  constructor(private socialProofService: SocialProofService) {
  }

  ngOnInit(): void {

    this.hideProofInterval = this.socialProofService.getSocialProofHideDuration();
    this.startSocialProofCycle();
    // this.hideProofInterval = this.socialProofService.getSocialProofHideDuration();
    // setTimeout(() => {
    //   this.showSocialProof = false;
    // }, 6000);
  }

  @HostListener('window:resize', ['$event']) onResize() {
    if(window.innerWidth < 521 ) {
      this.mobileScreenSize = true;
    } else {
      this.mobileScreenSize = false;
    }
  }


  private startSocialProofCycle() {
    setInterval(async () => {
      this.popupCounter++;
      // generate data
      this.socialProof = await this.socialProofService.generateSocialProofData(this.popupCounter);
      // show for 8 sec
      this.showSocialProof = true;
      this.hideProofInterval = this.socialProofService.getSocialProofHideDuration();
      setTimeout(() => {
        // hide
        this.showSocialProof = false;
      }, 6000);
    }, this.hideProofInterval);
  }

}
