import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { InstagramService } from 'src/app/services/instagram/instagram.service';
import { InstagramDialogData } from 'src/app/types/instagram-dialog-data';
import { InstagramUserData } from 'src/app/types/instagram-user-data';
import { faArrowLeft, faCreditCard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientPaymentRequest } from '../../types/client-payment-request';
import { CoinTypeEnum } from '../../enums/coinType.enum';
import { LanguageTypeEnum } from '../../enums/languageType.enum';
import { PaymentTypeEnum } from '../../enums/paymentType.enum';
import { AuthService } from '../../services/auth/auth.service';
import { PaymentService } from '../../services/payment/payment.service';
import { ProductTypeEnum } from '../../enums/productType.enum';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ProductAnalyticsEnum } from '../../enums/analytics/product.analytics.enum';
import { ThemeConfigService } from '../../services/theme-config/theme-config.service';

@Component({
  selector: 'app-instagram-followers-modal',
  templateUrl: './instagram-followers-modal.component.html',
  styleUrls: ['./instagram-followers-modal.component.scss']
})
export class InstagramFollowersModalComponent implements OnInit {

  public selectedInstagramUser: InstagramUserData = {
    id: '',
    profilePicUrl: '',
    userName: ''
  };

  public imgSrc: SafeUrl = '';
  public modalSubTitle: string = '';
  public modalTitle: string = 'Instagram Followers';
  public paymentText: string = '';
  public userFollowers: string = '';
  public userFollowing: string = '';

  public showSearchUsersStep: boolean = true;
  public showUserSummaryStep: boolean = false;
  public showProgressBar: boolean = false;
  public progressBarValue: number = 0;
  public progressDescription: string = '';

  public agreedToTerms: boolean = false;
  public paymentRouting: boolean = false;

  public creditCardIcon = faCreditCard;
  public backIcon = faArrowLeft;
  public closeIcon = faTimes;

  constructor(public dialogRef: MatDialogRef<InstagramFollowersModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: InstagramDialogData,
              private instagramService: InstagramService,
              private domSanitizer: DomSanitizer,
              public themeConfigService: ThemeConfigService,
              private analyticsService: AnalyticsService,
              private authService: AuthService,
              private paymentService: PaymentService,
              private router: Router,
              private translateService: TranslateService) {

    this.translateService.get('MODALS.INSTAGRAM_FOLLOWERS.SELECT_USER_STEP.TITLE').subscribe(translation => {
      this.modalTitle = translation;
    });
    this.setModalSubTitle();
    this.translateService.get('MODALS.INSTAGRAM_FOLLOWERS.SELECT_POSTS_STEP.PAYMENT_BUTTON').subscribe(translation => {
      this.paymentText = translation;
    });
    // this.modalTitle = this.data.title;
  }

  ngOnInit(): void {
  }

  closeModal(data?) {
    this.dialogRef.close(data);
  }

  setModalSubTitle() {

    if (this.data.numberOfFollowers && this.data.priceForFollowers) {
      this.translateService.get('MODALS.INSTAGRAM_FOLLOWERS.SELECT_USER_STEP.SUB_TITLE').subscribe(translation => {
        this.modalSubTitle = `${this.data.numberOfFollowers} ${translation} ${this.data.priceForFollowers}$`;
      });
    } else {
      console.log('number/price of followers not specified');
    }
  }

  userSelected(selectedUser: InstagramUserData) {

    this.showSearchUsersStep = false;
    this.showProgressBar = true;
    this.progressBarValue = 0;
    this.progressDescription = 'Connecting to Instagram';

    setTimeout(() => {
      this.progressBarValue = 45;
      this.progressDescription = 'Getting your profile data';

      setTimeout(() => {
        this.progressBarValue = 85;
        this.progressDescription = 'Finalizing';

        setTimeout(() => {
          this.progressBarValue = 100;
        }, 500);
      }, 500);

    }, 1500);

    this.translateService.get('MODALS.INSTAGRAM_FOLLOWERS.SELECT_POSTS_STEP.TITLE').subscribe(translation => {
      this.modalTitle = translation;
    });

    this.selectedInstagramUser = selectedUser;
    const price = this.data.priceForFollowers * this.data.numberOfFollowers;
    this.analyticsService.selectInstagramUser(price, ProductAnalyticsEnum.singleFollowers, selectedUser.userName);
    this.instagramService.getImageByUrl(this.selectedInstagramUser.profilePicUrl).then((response) => {

      this.imgSrc = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${response}`);
    });

    this.instagramService.getUserProfile(selectedUser.userName).then((response) => {
      const user = response;
      this.selectedInstagramUser.followers = user?.edge_followed_by?.count;
      this.selectedInstagramUser.following = user?.edge_follow?.count;

      this.userFollowers = this.selectedInstagramUser.followers.toLocaleString();
      this.userFollowing = this.selectedInstagramUser.following.toLocaleString();

      this.showProgressBar = false;
      this.showUserSummaryStep = true;

    }, (error: any) => {
      // disable spinner second step
    });
  }

  // userSummaryStep(){
  //   this.modalTitle = "Payment on Instagram Followers";
  //   this.showSearchUsersStep = false;
  //   this.showUserSummaryStep = true;
  // }

  searchUsersStep() {
    this.translateService.get('MODALS.INSTAGRAM_FOLLOWERS.SELECT_USER_STEP.TITLE').subscribe(translation => {
      this.modalTitle = translation;
    });
    this.showSearchUsersStep = true;
    this.showUserSummaryStep = false;
  }

  termsChanged(event: MatCheckboxChange) {
    this.agreedToTerms = event.checked;
  }

  async paymentPressed() {
    const authClient = await this.authService.getCurrentUser();
    const anonymousClient = this.authService.getAnonymousClient();
    this.paymentRouting = true;
    const paymentRequest: ClientPaymentRequest =
      {
        uid: authClient ? authClient.getUsername() : anonymousClient?.uid,
        socialUserId: this.selectedInstagramUser.userName,
        coinID: CoinTypeEnum.USD,
        products: [{
          id: '2',
          type: ProductTypeEnum.followers,
          name: this.modalSubTitle,
          paymentType: PaymentTypeEnum.single
        }],
        amount: this.data.numberOfFollowers,
        language: LanguageTypeEnum.english,
        links: [`https://www.instagram.com/${this.selectedInstagramUser.userName}`],
        isAnonymous: !authClient
      };
    const results = await this.paymentService.charge(paymentRequest);
    if (results.success) {
      const price = this.data.priceForFollowers * this.data.numberOfFollowers;
      const paymentData = {
        url: results.value,
        conversionValue: price,
        product: ProductAnalyticsEnum.singleFollowers,
        userName: this.selectedInstagramUser.userName
      };
      this.paymentService.storePayment(paymentData);
      this.router.navigate(['payment/process']).then(() => {
        this.closeModal(results.value);
      });
    } else {
      this.paymentRouting = false;
      this.paymentService.deletePayment();
      // TODO: error handling
    }

  }

  termsOfUseClicked() {
    this.router.navigate(['terms-of-use']).then(() => {
      this.closeModal();
    });
  }

}
