<form novalidate  class="form" autocomplete="off" [formGroup]="form">
  <div class="form-content" *ngIf="!verifyStep">
    <div class="input">
      <mat-form-field class="login-input">
        <mat-label class="input-label">{{userNameText | translate}}<span class="required">*</span></mat-label>
        <input autocomplete="off" type="email" name="email" autocomplete="email" matInput  formControlName="userName">
      </mat-form-field>
    </div>
    <div class="group-container" formGroupName="passwordGroup">
      <div class="input" >
        <mat-form-field class="signup-input">
          <mat-label class="input-label">{{'COMMON.FORM.NEW_PASSWORD' | translate}}<span class="required">*</span></mat-label>
          <input type="password" autocomplete="off" formControlName="password" matInput>
          <mat-error>{{getPasswordErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <div class="input">
        <mat-form-field class="signup-input">
          <mat-label class="input-label">{{'COMMON.FORM.CONFIRM_PASSWORD' | translate}}<span class="required">*</span></mat-label>
          <input type="password" autocomplete="off" [errorStateMatcher]="matcher" formControlName="confirmPassword" matInput>
          <mat-error>{{getConfirmPasswordErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <p *ngIf="error" class="error">
      {{ error }}
    </p>
    <button  [disabled]="!(form.valid && canSubmit)" (click)="submit()" mat-button cdkFocusInitial class="primary-button final-action-button">{{'RESET_PASSWORD.RESET_BUTTON' | translate}}</button>
    </div>
  <div class="form-content" *ngIf="verifyStep">
    <div class="input">
      <mat-form-field class="login-input">
        <mat-label class="input-label">{{'MODALS.VERIFY_CODE.AUTH_CODE' | translate}}<span class="required">*</span></mat-label>
        <input type="tel" autocomplete="off"  matInput  formControlName="code">
      </mat-form-field>
    </div>
    <div class="resend-token">
      <span *ngIf="!sendAgain" translate="MODALS.VERIFY_CODE.NO_AUTH_TOKEN"></span><span *ngIf="!sendAgain" class="link" (click)="sendCodeManually()"
                                                                                         translate="MODALS.VERIFY_CODE.RESEND_AUTH_TOKEN"></span>
      <span *ngIf="sendAgain" translate="MODALS.VERIFY_CODE.AUTH_TOKEN_SENT"></span>
    </div>
    <p *ngIf="error" class="error">
      {{ error }}
    </p>
    <button (click)="verifyCode()" [disabled]="!form.get('code').value"  mat-button cdkFocusInitial class="primary-button final-action-button">{{'MODALS.VERIFY_CODE.VERIFY' | translate}}</button>
<!--        <button (click)="sendCode()" *ngIf="!codeSent" [disabled]="!form.get('code').value"  mat-button  class="primary-button final-action-button">{{'MODALS.VERIFY_CODE.SEND_CODE' | translate}}</button>-->
    <!--    <button (click)="changeAuthType()" *ngIf="codeSent" [disabled]="!form.get('code').value"  mat-button  class="primary-button final-action-button">{{'MODALS.VERIFY_CODE.CHANGE_AUTH_TYPE' | translate}}</button>-->

  </div>
  <div class="mat-error form-content" *ngIf="errorMessage">{{errorMessage}}</div>
</form>
