<div class="login-modal">
    <div class="heading">
        <div class="heading-buttons">
          <div *ngIf="resetPasswordStep === 2" class="back-button-container" >
            <button mat-button  (click)="resetPasswordState(1)" class="back-button-modal"><fa-icon [icon]="backIcon"></fa-icon></button>
          </div>
          <div *ngIf="resetPasswordStep === 3" class="back-button-container" >
            <button mat-button  (click)="resetPasswordState(2)" class="back-button-modal"><fa-icon [icon]="backIcon"></fa-icon></button>
          </div>
            <div class="close-button-container">
                <button mat-button (click)="closeModal()" class="close-modal"><fa-icon [icon]="closeIcon"></fa-icon></button>
            </div>
        </div>
        <div  class="modal-title bold" mat-dialog-title>{{getTitle()}}</div>
    </div>
    <div mat-dialog-content>
      <form *ngIf="resetPasswordStep === 1 || resetPasswordStep === 4" class="form" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-content">
          <div class="input">
            <mat-form-field class="login-input">
              <mat-label class="input-label">{{'COMMON.FORM.EMAIL' | translate}}<span class="required">*</span></mat-label>
              <input autocomplete="off" formControlName="email" placeholder="ex: name@gmailcom" matInput>
              <mat-error>{{getEmailErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
          <div class="input">
            <mat-form-field class="login-input">
              <mat-label class="input-label">{{'COMMON.FORM.PASSWORD' | translate}}<span class="required">*</span></mat-label>
              <input type="password" autocomplete="off" formControlName="password" matInput>
              <mat-error>{{getPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
          <mat-error>{{errorMessage}}</mat-error>
          <div (click)="resetPasswordState(2)" class="lost-password">Lost your password?</div>
          <button type="submit" mat-button cdkFocusInitial class="primary-button final-action-button">{{'COMMON.FORM.LOGIN' | translate}}</button>

          <!--                <div class="remember-me-container">-->
          <!--                    <mat-checkbox [disableRipple]="true">-->
          <!--                        <span class="remember-me">Remember Me</span>-->
          <!--                    </mat-checkbox>-->
          <!--                </div>-->
        </div>
      </form>
      <app-reset-password (resetPasswordStepEvent)="onResetPasswordStep($event)" *ngIf="resetPasswordStep === 2 || resetPasswordStep === 3"></app-reset-password>
    </div>
</div>
