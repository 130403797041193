import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule),
    path: '',
  },
  {
    loadChildren: () => import('./pages/freemium/freemium.module').then(m => m.FreemiumModule),
    path: 'freemium',
  },
  {
    loadChildren: () => import('./pages/comments/comments.module').then(m => m.CommentsModule),
    path: 'comments',
  }, {
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
    path: 'payment',
  },
  {
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
    path: 'contact-us',
  },
  {
    loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule),
    path: 'about-us',
  },
  {
    loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule),
    path: 'terms-of-use',
  },
  {
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    path: 'privacy-policy',
  },
  {
    loadChildren: () => import('./pages/blog-all-articles/blog-all-articles.module').then(m => m.BlogAllArticlesModule),
    path: 'blog',
  },
  {
    loadChildren: () => import('./pages/blog-article/blog-article.module').then(m => m.BlogArticleModule),
    path: 'article/:id',
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
