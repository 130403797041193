import {Injectable} from '@angular/core';
import {PaidServiceType} from 'src/app/enums/paidServiceType';
import {SocialProofData} from 'src/app/types/social-proof-data';
import {PlansService} from '../pricing-plans/pricing-plans.service';
import {HttpClient} from '@angular/common/http';
import {IpGeolocation} from '../../types/ip-geolocation';
import {RandomUser} from 'src/app/types/random-user';
import {RandomGeolocation} from 'src/app/types/random-geolocation';
import {SocialProofTypeEnum} from '../../enums/Social-proof-type.enum';
import {environment} from 'src/environments/environment.local';
import * as e from 'express';
import {ThemeConfigService} from "../theme-config/theme-config.service";

@Injectable({
  providedIn: 'root'
})
export class SocialProofService {

  private socialProof: SocialProofData = {
    country: '',
    flagPath: '',
    name: '',
    region: '',
    serviceAmount: '',
    serviceType: ''
  };
  private likesSubscriptionAmount: string = '';
  private countries: RandomGeolocation[] = [
    {
      countryName: 'Canada',
      countryCode: 'ca',
      countryRegion: ['YT', 'NL', 'BC', 'NU', 'NT', 'NS', 'PE', 'QC', 'MB', 'AB']
    },
    {
      countryName: 'France',
      countryCode: 'fr',
      countryRegion: ['CVL', 'GES', 'NOR', 'NAQ', 'ARA', 'BFC', 'HDF', 'PDL']
    },
    {
      countryName: 'Germany',
      countryCode: 'de',
      countryRegion: ['NI', 'BY', 'BW', 'NW', 'ST', 'MV', 'HE', 'BB', 'RP', 'TH']
    },
    {
      countryName: 'Italy',
      countryCode: 'us',
      countryRegion: ['LOM', 'TUS', 'CAM', 'EMR', 'LAT', 'CAL', 'PIE', 'ABR', 'APU', 'FVG']
    },
    {
      countryName: 'Netherlands',
      countryCode: 'nl',
      countryRegion: ['DR', 'FL', 'FR', 'GE', 'GR', 'LI', 'NB', 'NH', 'OV', 'ZH']
    },
    {
      countryName: 'Sweden',
      countryCode: 'us',
      countryRegion: ['LP', 'VB', 'DR', 'SM', 'UP', 'VR', 'VB', 'NB', 'HS', 'VG']
    },
    {
      countryName: 'United Kingdom',
      countryCode: 'gb',
      countryRegion: ['CHI', 'ENG', 'IOM', 'IRL', 'NIR', 'SCT', 'WAL']
    },
    {
      countryName: 'USA',
      countryCode: 'us',
      countryRegion: ['MT', 'WY', 'TX', 'IA', 'FL', 'NY', 'VA', 'NM', 'CO', 'GA']
    },
  ];

  private subAmountArray: string[] = [];
  private numOfServiceTypes: number = 3;
  private instagramLikesAmount: number = 200;
  private instagramFollowersAmount: number = 200;
  private defaultCountryCode: string = 'us';
  private defaultCountry: string = 'USA';
  private defaultCountryRegion: string = 'NY';
  private randomGeolocation: RandomGeolocation;
  private ipGeolocation: IpGeolocation;
  private randomUser: RandomUser;


  constructor(private plansService: PlansService, private httpClient: HttpClient, private themeConfigService: ThemeConfigService) {
    this.initLikesSubArray();
  }

  private async initLikesSubArray() {
    const pricingPlans: any = await this.plansService.getPricingPlans();
    pricingPlans.forEach((pricingPlan, index) => {
      if (index % 2 === 0) {
        this.subAmountArray.push(this.plansService.getPackageName(pricingPlan));
      }
    });

    // return new Promise((resolve)=>{resolve('1')});
  }

  private getLikesSubscriptionAmount(): string {
    const index = Math.floor(Math.random() * this.subAmountArray.length);
    return this.subAmountArray[index];
  }

  private getInstagramLikesAmount(): string {
    const currentInstagramLikesAmount = this.instagramLikesAmount;
    if (this.instagramLikesAmount <= 10000) {
      this.instagramLikesAmount += 100;
    } else {
      this.instagramLikesAmount = 200;
    }

    return currentInstagramLikesAmount.toString();
  }

  // getLikesFollowersAmount
  private getInstagramFollowersAmount(): string {
    const currentInstagramFollowersAmount = this.instagramFollowersAmount;
    if (this.instagramFollowersAmount <= 10000) {
      this.instagramFollowersAmount += 100;
    } else {
      this.instagramFollowersAmount = 200;
    }

    return currentInstagramFollowersAmount.toString();
  }

  private getRandomGeolocation(): RandomGeolocation {
    return this.countries[Math.floor(Math.random() * this.countries.length)];
  }

  private getRandomRegion(regionList: string[]) {
    return regionList[Math.floor(Math.random() * regionList.length)];
  }

  private getCountry(): Promise<IpGeolocation> {
    //  return this.httpClient.get<IpGeolocation>(environment.locationApi).toPromise();
    return this.httpClient.get(`${this.themeConfigService.themeConfig.backendUrl}/ip`).toPromise().then((results: any) => {
      return results.value;
    });
  }

  private async getRandomUser(cCode: string): Promise<RandomUser> {
    const countryCode = this.getCountryCode(cCode);
    const data: RandomUser = await this.httpClient.get<RandomUser>(`${environment.randomUserApi}${countryCode}`).toPromise();
    return data;
  }

  private getCountryName(countryName: string) {
    return this.countries.find((country: RandomGeolocation) => country.countryName === countryName)?.countryName || this.defaultCountry;
  }

  private getCountryCode(countryCode: string) {
    return this.countries.find((country: RandomGeolocation) => country.countryCode === countryCode.toLocaleLowerCase())?.countryCode || this.defaultCountryCode;
  }

  private getRegion(region: string) {
    if (!region) {
      return this.getRandomRegion(this.randomGeolocation.countryRegion);
    };
    const isCountryFromList = this.countries.some((country: RandomGeolocation) => country.countryName === this.ipGeolocation.country);
    if (isCountryFromList) {
      return region;
    } else {
      return this.defaultCountryRegion;
    }
  }

  public getUserName(userData: RandomUser): string {
    const firstName = userData.results[0].name.first || 'Johnatan';
    const lastName = userData.results[0].name.last.slice(0, 1) || 'H';
    return `${firstName}, ${lastName}.`;
  }

  private getFlagPath(country: string) {
    return `/assets/icons/flags/${country}.svg`;
  }

  private getServiceType(): string {
    const serviceType = Math.floor(Math.random() * this.numOfServiceTypes);
    let purchaseDetails = '';
    switch (serviceType) {
      case PaidServiceType.InstagramFollowers:
        purchaseDetails = SocialProofTypeEnum.InstagramLikes;
        break;
      case PaidServiceType.InstagramLikes:
        purchaseDetails = SocialProofTypeEnum.InstagramFollowers;
        break;
      case PaidServiceType.LikesSubscription:
        purchaseDetails = SocialProofTypeEnum.InstagramSubscription;
        break;
    }

    this.socialProof.serviceType = purchaseDetails;
    return purchaseDetails;
  }

  private getServiceAmountByType(): string {
    const serviceType = this.socialProof.serviceType;
    let serviceAmount = '';
    switch (serviceType) {
      case SocialProofTypeEnum.InstagramLikes:
        serviceAmount = this.getInstagramLikesAmount();
        break;

      case SocialProofTypeEnum.InstagramFollowers:
        serviceAmount = this.getInstagramFollowersAmount();
        break;

      case SocialProofTypeEnum.InstagramSubscription:
        serviceAmount = this.getLikesSubscriptionAmount();
        break;
    }

    return serviceAmount;
  }

  public async setSocialProof(ipGeolocation: IpGeolocation) {
    this.randomGeolocation = this.getRandomGeolocation();
    this.randomUser = await this.getRandomUser(ipGeolocation?.countryCode || this.randomGeolocation.countryCode);
    this.socialProof.country = this.getCountryName(ipGeolocation?.country || this.randomGeolocation.countryName);
    this.socialProof.flagPath = this.getFlagPath(this.socialProof.country);
    this.socialProof.region = this.getRegion(ipGeolocation?.region);
    this.socialProof.name = this.getUserName(this.randomUser);
  }

  public async generateSocialProofData(popupCounter: number): Promise<SocialProofData> {
    if (popupCounter === 1 || popupCounter === 6) {
      this.ipGeolocation = await this.getCountry();
      console.log(this.ipGeolocation)
      await this.setSocialProof(this.ipGeolocation);
    } else {
      await this.setSocialProof(null);
    }
    // generate service type
    this.socialProof.serviceType = this.getServiceType();
    // generate service amount
    this.socialProof.serviceAmount = this.getServiceAmountByType();
    return this.socialProof;
  }

  public getSocialProofHideDuration(): number {
    return Math.floor(Math.random() * 5000) + 10000; // generates a number between 4999 and 9999
  }
}
