import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { v4 as uuidv4 } from 'uuid';

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  private milisecondsInADay = 86400000;
  private scrollOptions = {behavior: 'smooth', block: 'start'};

  constructor() {
  }

  public numberWithCommas(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public validatePasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {notEven: true};
  }

  public scrollToElement(elementId: string) {

    const offset = document.getElementById('toolbar').clientHeight;
    const element = document.getElementById(elementId);
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    if (element) {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  public createGuid(): string {
    return uuidv4();
  }

  public scrollToTop() {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 0);

  }

  public dayHasPassed(dateA: number, dateB: number): boolean {
    return (dateB - dateA) >= this.milisecondsInADay;
  }
  setStore(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getStore(key) {
    const data: any = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return {};
  }
}
