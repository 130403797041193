import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Plan, PricingPlanGroup} from 'src/app/types/plan';
import {faCheckCircle, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {PlanTypeEnum} from '../../enums/planType.enum';
import {AnalyticsService} from '../../services/analytics/analytics.service';
import {ProductAnalyticsEnum} from '../../enums/analytics/product.analytics.enum';
import {CommentPlans} from 'src/app/types/comment-plans';
import {CommentPlanTypeEnum} from 'src/app/enums/commentPlanType.enum';
import {PlansService} from "../../services/pricing-plans/pricing-plans.service";


@Component({
  selector: 'app-comments-pricing-card',
  templateUrl: './comments-pricing-card.component.html',
  styleUrls: ['./comments-pricing-card.component.scss']
})
export class CommentsPricingCardComponent implements OnInit {

  @Output() onSelectedPricingPlan: EventEmitter<Plan>;

  @Input() pricingPlanGroup: CommentPlans;
  @Input() selectedCommentsAmount: number;
  @Input() selectedPlanType: CommentPlanTypeEnum;

  public planTypeSelected: PlanTypeEnum = PlanTypeEnum.weekly;
  public commentsPlanTypeSelected: CommentPlanTypeEnum;
  public subscribeIcon = faChevronRight;
  checkIcon = faCheckCircle;

  constructor(private analyticsService: AnalyticsService, private plansService: PlansService) {
    this.onSelectedPricingPlan = new EventEmitter<Plan>();
  }

  ngOnInit(): void {
  }

  changeSelectedPlan(planType: PlanTypeEnum) {
    this.planTypeSelected = planType;
  }

  subscribeToPlanClicked() {
    if (this.isPlanYearly()) {
      this.analyticsService.subscribeClick(this.pricingPlanGroup.price[this.selectedCommentsAmount],
        this.plansService.getCommentPackageName(this.pricingPlanGroup), ProductAnalyticsEnum.likesSubscription, PlanTypeEnum.yearly);
    } else {
      this.analyticsService.subscribeClick(this.pricingPlanGroup.price[this.selectedCommentsAmount],
        this.plansService.getCommentPackageName(this.pricingPlanGroup), ProductAnalyticsEnum.likesSubscription, PlanTypeEnum.monthly);
    }
    this.plansService.getPricingPlanById(this.pricingPlanGroup.ids[this.selectedCommentsAmount]).then(plan => {
      this.onSelectedPricingPlan.emit(plan);
    });
  }

  isPlanYearly(): boolean {
    return this.planTypeSelected === PlanTypeEnum.yearly;
  }

  isPlanMonthly(): boolean {
    return this.planTypeSelected === PlanTypeEnum.monthly;
  }
  isBestSeller() {
   return this.pricingPlanGroup.mostPopular;
  }
  getPrice() {
    return this.pricingPlanGroup.price[this.selectedCommentsAmount.toString()];
  }

}
