import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slider-widget',
  templateUrl: './slider-widget.component.html',
  styleUrls: ['./slider-widget.component.scss']
})
export class SliderWidgetComponent implements OnInit {

  @Input() min: string = '100';
  @Input() max: string = '10000';
  @Input() step: string = '100';
  @Output() onSliderValueChanged: EventEmitter<number>;

  constructor() {
    this.onSliderValueChanged = new EventEmitter<number>();
  }

  ngOnInit(): void {
  }

  onValueChanged(event: any) {
    this.onSliderValueChanged.emit(event.value);
  }

}
