import { Injectable } from '@angular/core';
import { Client } from 'src/app/types/client';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ClientRequest } from '../../types/client-request';
import { ThemeConfigService } from '../theme-config/theme-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private authService: AuthService, private httpClient: HttpClient, private themeConfigService: ThemeConfigService) {
  }

  public getUserProfileById(id: string): Promise<Client> {
    return this.httpClient.get<Client>(this.themeConfigService.themeConfig.backendUrl + '/user', {
      headers: {
        Uid: id,
        brandId: this.themeConfigService.themeConfig.brandId
      }
    }).toPromise();
  }

  public updateUserProfile(clientRequest: ClientRequest): Promise<any> {
    return this.httpClient.put(`${this.themeConfigService.themeConfig.backendUrl}/user`, clientRequest).toPromise();
  }

  public async getCurrentUserProfile(): Promise<Client> {
    const cachedUser = localStorage.getItem('client');
    if (cachedUser) {
      return JSON.parse(cachedUser);
    } else {
      const user = await this.authService.getCurrentUser();
      if (user && user.getUsername()) {
        const userProfile: any = await this.getUserProfileById(user.getUsername());
        if (userProfile.success) {
          localStorage.setItem('client', JSON.stringify(userProfile.value));
          return userProfile.value;
        } else {
          localStorage.removeItem('client');
          return null;
        }
      } else {
        localStorage.removeItem('client');
        return null;
      }
    }
  };
}
