import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InstagramService } from 'src/app/services/instagram/instagram.service';
import { InstagramUserData } from 'src/app/types/instagram-user-data';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { InstagramPost } from 'src/app/types/instagram-post';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {

  @Input() includePostsStep: boolean = true;
  @Output() selectedPostsChanged: EventEmitter<InstagramPost[]>;
  @Output() nextStep: EventEmitter<InstagramUserData>;
  @Output() previousStep: EventEmitter<any>;
  @Output() searchingUser: EventEmitter<string>;
  @Output() selectedUser: EventEmitter<InstagramUserData>;

  public instagramInput: string = '';
  public showUserResults: boolean = false;
  public showUserPosts: boolean = false;
  public showProgressBar: boolean = false;
  public progressBarValue: number = 0;
  public progressDescription: string = '';
  public showNoUsersFoundMessage: boolean = false;
  public showNoPostsFoundMessage: boolean = false;
  public heartIcon = faHeart;
  public selectedInstagramUser: InstagramUserData = {
    id: '',
    profilePicUrl: '',
    userName: '',
  };

  // @ts-ignore
  @ViewChild('usersSearchInput', {static: true}) usersSearchInput: ElementRef;
  private MaxNumberOfResults = 8;

  public usersResults: InstagramUserData[] = [];
  public userPosts: InstagramPost[] = [];
  public selectedUserPosts: InstagramPost[] = [];

  constructor(public instagramService: InstagramService) {
    this.selectedPostsChanged = new EventEmitter<InstagramPost[]>();
    this.nextStep = new EventEmitter<any>();
    this.previousStep = new EventEmitter<any>();
    this.searchingUser = new EventEmitter<any>();
    this.selectedUser = new EventEmitter<InstagramUserData>();
  }

  ngOnInit(): void {
    fromEvent(this.usersSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater or equal to 3
      , filter(res => res.length >= 3)
      // Time in milliseconds between key events
      , debounceTime(500)
      // If previous query is different from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.showNoUsersFoundMessage = false;
      this.showNoPostsFoundMessage = false;

      this.showProgressBar = true;
      this.progressDescription = 'searching in instagram.';
      this.progressBarValue = 0;
      setTimeout(() => {

        this.progressBarValue = 45;
        setTimeout(() => {

          this.progressBarValue = 85;
          setTimeout(() => {

            this.progressBarValue = 85;
          }, 200);
        }, 750);
      }, 750);

      this.showUserResults = false;
      if (this.showUserPosts) {
        this.showUserPosts = false;
        this.previousStep.emit();
      }
      this.instagramService.findAccounts(text).then((response) => {
        this.searchingUser.next(text);
        this.showUserResults = true;
        this.showProgressBar = false;
        if (response?.length > 0) {
          this.usersResults = response.splice(0, this.MaxNumberOfResults);
        } else {
          this.showNoUsersFoundMessage = true;
        }
        setTimeout(() => {
       //   this.scrollIssue('search-results');
        });
      }, (error) => {
        this.showProgressBar = false;
        this.showNoUsersFoundMessage = true;
      });
    });
    // this.scrollIssue('user-posts');
  }

  scrollIssue(selector) {
    let lastY = 0;
    const targetElt: any = document.querySelector('.' + selector);

    targetElt.addEventListener('touchstart', (event) => {
      lastY = event.touches[0].clientY;
      console.log('touchstart');
    });

    targetElt.addEventListener('touchmove', (event) => {
      const top = event.touches[0].clientY;

      const scrollTop = event.currentTarget.scrollTop;
      const maxScrollTop = event.currentTarget.scrollHeight - event.currentTarget.outerHeight;
      const direction = lastY - top < 0 ? 'up' : 'down';
      if (
        event.cancelable && (
          (scrollTop <= 0 && direction === 'up') ||
          (scrollTop >= maxScrollTop && direction === 'down')
        )
      ) {
        event.preventDefault();
      }

      lastY = top;
      console.log('touchmove');
    });
  }

  usersStep() {
    this.showUserResults = true;
    this.showUserPosts = false;
  }

  postsStep() {
    this.showUserResults = false;
    this.showUserPosts = true;
  }

  onSelectedUser(selectedUser: InstagramUserData) {
    this.showProgressBar = true;
    this.progressBarValue = 0;
    this.progressDescription = 'Connecting to Instagram';
    setTimeout(() => {
      this.progressBarValue = 45;
      this.progressDescription = 'Getting your profile data';

      setTimeout(() => {
        this.progressBarValue = 85;
        this.progressDescription = 'Finalizing';

        setTimeout(() => {
          this.progressBarValue = 100;
        }, 500);
      }, 500);

    }, 1500);

    this.showUserResults = false;
    this.selectedUser.emit(selectedUser);
    if (this.includePostsStep) {
      this.instagramService.getAccountPosts(selectedUser.id).then((response) => {
        this.showProgressBar = false;
        this.showUserPosts = true;
        setTimeout(() => {
       //   this.scrollIssue('user-posts');
        });
        this.showNoPostsFoundMessage = false;
        this.nextStep.emit(selectedUser);

        if (response?.length > 0) {
          this.userPosts = response;
        } else {
          this.showNoPostsFoundMessage = true;
        }
      }, (error) => {
        this.showNoPostsFoundMessage = true;
        // disable spinner second step
      });


    } else {
      this.nextStep.emit(selectedUser);
    }

  }

  selectedPost(instagramPost: InstagramPost) {
    instagramPost.selected = !instagramPost.selected;
    if (instagramPost.selected) {
      this.selectedUserPosts.push(instagramPost);
    } else {
      this.selectedUserPosts.splice(this.selectedUserPosts.indexOf(instagramPost), 1);
    }

    this.selectedPostsChanged.emit(this.selectedUserPosts); // return new array and not ref
  }
}
