/* tslint:disable:no-string-literal */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ClientResponse} from '../../types/client-response';
import {Client} from '../../types/client';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject, Subject} from 'rxjs';
import {Auth} from 'aws-amplify';
import {CognitoUser, ISignUpResult} from 'amazon-cognito-identity-js';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ResetPasswordStatusEnum} from '../../enums/reset-password-status.enum';
import {UtilitiesService} from '../utilities/utilities.service';
import {ThemeConfigService} from '../theme-config/theme-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuth: Subject<boolean> = new Subject<boolean>();

  // private authUser: ReplaySubject<CognitoUser> = new ReplaySubject<CognitoUser>();

  constructor(private httpClient: HttpClient,
              private translateService: TranslateService,
              private activatedRoute: ActivatedRoute,
              private matDialog: MatDialog,
              private utilService: UtilitiesService,
              private router: Router,
              private themeConfigService: ThemeConfigService
  ) {

    this.isAuthenticated();
  }

  public async providerForgotPassword(userName: string): Promise<ResetPasswordStatusEnum> {
    try {
      return await Auth.forgotPassword(userName);
    } catch (error) {
      console.log('error forgot Password', error);
      return Promise.reject(error);
    }
  }

  public async providerConfirmPassword(userName: string, newPassword: string, verificationCode: string): Promise<any> {
    return Auth.forgotPasswordSubmit(userName, verificationCode, newPassword);
  }

  public async confirmCode(type: string, verificationCode: string): Promise<any> {
    return Auth.verifyCurrentUserAttributeSubmit(type, verificationCode);
  }

  public async sendCode(type: string): Promise<any> {
    return await Auth.verifyCurrentUserAttribute(type);
  }

  public async providerLogin(userName: string, password: string): Promise<CognitoUser> {
    try {
      const user = await Auth.signIn(userName, password);
      console.log(user);
      this.isAuth.next(true);
      return user;
    } catch (error) {
      console.log('error signing in', error);
      this.isAuth.next(false);
      return Promise.reject(error);
    }
  }

  public async providerSignup(client: Client, password: string): Promise<CognitoUser> {

    return Auth.signUp({
      username: client.email,
      password,
      attributes: {
        email: client.email,
        'custom:plan_id': client.packageId,
        'custom:brand_id': client.brandId,
        'custom:social_user_id': client.social.instagram.id.toString(),
        'custom:social_user_name': client.social.instagram.userName,
      }
    }).then((result: ISignUpResult) => {
      return result.user;
    }).catch(error => {
      console.log('error signing up:', error);
      return Promise.reject(error);
    });
  }


  public async logout() {
    await Auth.signOut();
    this.isAuth.next(false);
    localStorage.removeItem('clientToken');
    localStorage.removeItem('client');
  }

  public async getCurrentUser(): Promise<CognitoUser | null> {
    return Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        return user;
      })
      .catch((error) => {
        console.log('error getCurrentUser:', error);
        return null;
      });
  }

  public isAuthenticated(): Promise<boolean> {
    return Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        this.isAuth.next(true);
        return true;
      })
      .catch((error) => {
        console.log('error isAuthenticated:', error);
        this.isAuth.next(false);
        return false;
      });
  }

  public getAnonymousClient(): { uid: string } {
    let client;
    if (localStorage.getItem('anonymousClient')) {
      client = JSON.parse(localStorage.getItem('anonymousClient'));
    } else {
      client = {uid: this.utilService.createGuid()};
      localStorage.setItem('anonymousClient', JSON.stringify(client));
    }
    return client;
  }

  public getClientToken(): any {
    return Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
      if (user) {
        return user.getSignInUserSession()?.getIdToken().getJwtToken();
      }
      return null;
    });
  }

  sendInfoForEmail(email: string, password: any) {
    const body = {
      email,
      password: btoa(password)
    };

    return this.httpClient.post(`${this.themeConfigService.themeConfig.backendUrl}/email/password`, body)
      .toPromise();

  }
}
