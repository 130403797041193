<div class="pricing-card" [id]="isBestSeller()?'pricing-card-best-seller':''">
    <mat-card >
        <div class="plan-type">
            <button class="weekly" [ngClass]="isPlanWeekly() ? 'active-plan' : 'inactive-plan'"  mat-button (click)="changeToWeekly()">
                <span class="plan-type-text">{{'MAIN.PRICING_SECTION.PRICING_CARD.PLAN_TYPE.WEEKLY' | translate}}</span>
            </button>
            <button class="monthly" [ngClass]="isPlanMonthly() ? 'active-plan' : 'inactive-plan'" mat-button (click)="changeToMonthly()">
                <span class="plan-type-text">{{'MAIN.PRICING_SECTION.PRICING_CARD.PLAN_TYPE.MONTHLY' | translate}}</span>
            </button>
        </div>
        <mat-card-content>
          <span class="best-seller" *ngIf="isBestSeller()">Best Seller</span>
            <div>
                <div class="pricing-titles ta-center">
                    <div><span>{{getMinLikes()}}</span> - <span>{{getMaxLikes()}}</span></div>
                    <div>{{'MAIN.PRICING_SECTION.PRICING_CARD.LIKES_PER_POSTS' | translate}}</div>
                </div>
                <div class="divider"></div>
                <mat-list class="benefits-list" role="list">
                    <mat-list-item role="listitem" *ngFor="let feature of pricingPlanGroup.weeklyPlan.features">
                        <fa-icon [icon]="checkIcon"></fa-icon>
                        <span class="list-item">{{('MAIN.PRICING_SECTION.PRICING_CARD.FEATURES.'+ feature) | translate}}</span>
                    </mat-list-item>
                </mat-list>
                <div class="divider"></div>
                <div class="ta-center">
                    <div class="price">
                        <span>{{getPrice()}}</span>
                        <span *ngIf="isPlanWeekly()">{{'MAIN.PRICING_SECTION.PRICING_CARD.PRICE.WEEK' | translate }}</span>
                        <span *ngIf="isPlanMonthly()">{{'MAIN.PRICING_SECTION.PRICING_CARD.PRICE.MONTH' | translate }}</span>
                    </div>
                    <button class="primary-button pricing-plan-button" mat-button (click)="subscribeToPlanClicked()">
                        {{'MAIN.PRICING_SECTION.PRICING_CARD.SUBSCRIBE_BUTTON' | translate}}
                        <fa-icon [icon]="subscribeIcon"></fa-icon>
                    </button>
                    <div class="cancel-text m-t-10">{{'MAIN.PRICING_SECTION.PRICING_CARD.CANCEL' | translate}}</div>
                </div>
            </div>
        </mat-card-content>

    </mat-card>
</div>
