import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../environments/environment';
import { ThemeConfigService } from '../../services/theme-config/theme-config.service';

export interface DialogDataData {
  resetPassword: boolean;
}

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(false)
  });
  public errorMessage;
  public closeIcon = faTimes;
  public backIcon = faArrowLeft;
  public resetPasswordStep = 1;

  constructor(public dialogRef: MatDialogRef<LoginModalComponent>,
              private authService: AuthService,
              private themeService: ThemeConfigService,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataData,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this.data.resetPassword) {
      this.resetPasswordStep = 2;
    }
  }

  getTitle() {
    if (this.resetPasswordStep === 1) {
      return 'Please fill in the following details to log into your account';
    } else if (this.resetPasswordStep === 2) {
      return 'Please fill in the following details to Reset your account';
    } else if (this.resetPasswordStep === 3) {
      return 'The verification code was sent to the email address entered at registration. Please enter the received code';
    } else if (this.resetPasswordStep === 4) {
      return 'Please fill in the NEW credentials to log into your account';
    }
    return '';
  }

  onResetPasswordStep(state) {
    this.resetPasswordState(state);
  }

  resetPasswordState(state) {
    this.resetPasswordStep = state;
  }

  getEmailErrorMessage(): string {

    let error: string = '';

    if (this.loginForm.get('email')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    if (this.loginForm.get('email')?.hasError) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.INVALID', {field: 'email'}).subscribe(translation => {
        error = translation;
      });
    } else {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.UNKNOWN', {field: 'email'}).subscribe(translation => {
        error = translation;
      });
    }

    return error;
  }

  getPasswordErrorMessage(): string {
    let error: string = '';

    if (this.loginForm.get('password')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    return error;
  }

  getAuthenticationError() {
    // TODO
  }

  closeModal() {
    this.dialogRef.close();
  }

  async onSubmit() {
    this.errorMessage = '';
    if (this.loginForm.valid) {
      const value = this.loginForm.value;
      try {
        await this.authService.providerLogin(value.email, value.password);
        location.href = this.themeService.themeConfig.dashboardUrl;
      } catch (error) {
        this.errorMessage = error.message;
      }
    }
  }
}
