import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientPaymentRequest } from '../../types/client-payment-request';
import { environment } from '../../../environments/environment';
import { ProductAnalyticsEnum } from '../../enums/analytics/product.analytics.enum';
import { PlanTypeEnum } from '../../enums/planType.enum';
import { ThemeConfigService } from '../theme-config/theme-config.service';

export interface PaymentData {
  url: string;
  conversionValue: number;
  product: ProductAnalyticsEnum;
  packageName?: string;
  freemium?: boolean;
  renewable?: PlanTypeEnum;
  userName: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient, private themeConfigService: ThemeConfigService) {
  }

  public charge(payment: ClientPaymentRequest): any {
    return this.httpClient.post(`${this.themeConfigService.themeConfig.backendUrl}/payment/createCharge`, payment, {
      headers: {
        brandId: this.themeConfigService.themeConfig.brandId
      }
    }).toPromise();
  }

  public storePayment(paymentData: PaymentData) {
    localStorage.setItem('payment', JSON.stringify(paymentData));
  }

  public getPayment(): PaymentData {
    const data = localStorage.getItem('payment');
    return data ? JSON.parse(data) : null;
  }

  public deletePayment() {
    localStorage.removeItem('payment');
  }
}
