import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { InstagramService } from 'src/app/services/instagram/instagram.service';
import { InstagramUserData } from 'src/app/types/instagram-user-data';

@Component({
  selector: 'app-instagram-user',
  templateUrl: './instagram-user.component.html',
  styleUrls: ['./instagram-user.component.scss']
})
export class InstagramUserComponent implements OnInit {

  @Input() userData: InstagramUserData = {
    userName: '',
    profilePicUrl: '',
    id: ''
  };

  public imgSrc: SafeUrl = '';

  constructor(private instagramService: InstagramService, private domSanitizer: DomSanitizer) {
  }


  ngOnInit(): void {
    this.instagramService.getImageByUrl(this.userData.profilePicUrl).then((response) => {

      this.imgSrc = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${response}`);
    });
  }

}
