<div class="reviews">
    <div *ngFor="let review of reviewsList" class="review-container">
        <div class="review-video">
            <div *ngIf="review.showThumbnail"
                [ngStyle]="{'background-image': 'url(' + review.thumbnailSrc + ')'}"
                class="thumbnail-container">
                <fa-icon (click)="clickedThumbnail(review)" [icon]="youtubePlayIcon"></fa-icon>
            </div>
            <iframe *ngIf="!review.showThumbnail && review.videoSrc" frameborder="0" allowfullscreen="1"
                allow="autoplay;" title="YouTube video player" height="360"
                    [src]="review.videoSrc | safe" >
            </iframe>
        </div>
        <div class="review-text">
        {{review.reviewerName}} <span>reviewed</span><br>{{review.productName}}
        </div>
        <div class="review-stars">
            <div>☆</div>
            <div>☆</div>
            <div>☆</div>
            <div>☆</div>
            <div>☆</div>
        </div>
    </div>

</div>
