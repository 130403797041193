import { Component, OnInit, SecurityContext } from '@angular/core';
import { ThemeConfigService } from 'src/app/services/theme-config/theme-config.service';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Review } from 'src/app/types/review';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  youtubePlayIcon = faYoutube;
  public reviewsList:Review[] = [];

  constructor(public themeService: ThemeConfigService) {
    this.reviewsList = themeService.themeConfig.reviews;
  }

  ngOnInit(): void {

  }

  clickedThumbnail(review: Review) {
      review.showThumbnail = false;
  }


}
