<div
    *ngIf="socialProof" class="social-proof"
    [ngClass]="{'hide': !showSocialProof, 'show': showSocialProof, 'mobile-size': mobileScreenSize}"
>
    <div class="social-proof-container">
        <div class="flag">
            <svg-icon [src]="socialProof?.flagPath"></svg-icon>
        </div>
        <div class="spacer"></div>
        <div class="details">
            <div class="name-and-location">
                <span class="name bold">Someone</span> from <span class="location bold">{{socialProof.country}}, {{socialProof.region}}</span>
            </div>
            <div class="purchase">
                Purchased <span class="purchase-details">{{socialProof.serviceAmount}} {{socialProof.serviceType}}</span><span class="desktop-size"> a few seconds ago <fa-icon [icon]="checkIcon"></fa-icon><span class="verified"> verified</span></span>
            </div>
        </div>
    </div>
</div>
