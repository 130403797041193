<div class="buy-followers-modal">
    <div class="heading">
        <div class="heading-buttons">
            <div *ngIf="showUserSummaryStep" class="back-button-container" >
                <button mat-button  (click)="searchUsersStep()" class="back-button-modal">
                  <fa-icon [icon]="backIcon"></fa-icon>
                </button>
            </div>
            <div class="close-button-container">
                <button mat-button (click)="closeModal()" class="close-modal">
                  <fa-icon [icon]="closeIcon"></fa-icon>
                </button>
            </div>
          </div>
        <div class="modal-title bold" mat-dialog-title>{{modalTitle}}</div>
        <div class="modal-sub-title" mat-dialog-title >{{modalSubTitle}}</div>
    </div>
    <div class="modal-content">
        <div class="dialog-content" *ngIf="showSearchUsersStep" mat-dialog-content>
            <div class="users-content">
                <app-instagram
                    [includePostsStep]="false"
                    (nextStep)="userSelected($event)"
                ></app-instagram>
            </div>
        </div>
        <div *ngIf="showProgressBar" class="progress-bar">
            <mat-progress-bar value={{progressBarValue}} ></mat-progress-bar>
            <div class="progress-description">{{progressDescription}}</div>
        </div>
        <div *ngIf="showUserSummaryStep" class="user-summary" mat-dialog-content>
            <div class="user-summary-content">
                <div [ngClass]="{'overlay':paymentRouting}" class="user-summary-details">
                    <div class="details-section">
                        <div>
                            Followers: {{userFollowers}}
                        </div>
                        <div>
                            Following: {{userFollowing}}
                        </div>
                    </div>
                    <div class="details-section">
                        <img [src]="imgSrc" alt="avatar">
                    </div>
                    <div class="details-section">
                        <div class="user-name">
                            {{selectedInstagramUser.userName}}
                        </div>
                        <div class="full-name">
                            {{selectedInstagramUser.fullName}}
                        </div>
                    </div>
                </div>
                <mat-checkbox [ngClass]="{'overlay':paymentRouting}" [checked]="agreedToTerms" (change)="termsChanged($event)" [disableRipple]="true" [required]="true">
                    <span class="terms-checkbox">
                        {{'COMMON.AGREE_TO_TERMS.I_AGREE' | translate}}
                        <a class="text-underline" (click)="termsOfUseClicked()">
                            {{'COMMON.AGREE_TO_TERMS.TERMS_LINK' | translate}}
                        </a>
                      {{themeConfigService?.themeConfig?.siteName}}
                    </span>
                </mat-checkbox>
                <div mat-dialog-actions>
                    <button class="primary-button final-action-button payment-button" [ngClass]="{'disabled' : paymentRouting || !agreedToTerms}" [disabled]="paymentRouting || !agreedToTerms" mat-button cdkFocusInitial (click)="paymentPressed()">
                        <fa-icon [icon]="creditCardIcon"></fa-icon>{{paymentText}}
                    </button>
                </div>
            </div>
        </div>
      <mat-spinner *ngIf="paymentRouting" color="primary"></mat-spinner>
    </div>
</div>
