import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public paymentStatusEntered = new Subject<boolean>();
  private modalOpened = new Subject<boolean>();

  constructor() {}

  getModalStatus(){
    return this.modalOpened.asObservable();
  }

  setModalStatus(state:boolean){
    this.modalOpened.next(state);
  }

  confirmPaymentStatusEntered(state: boolean) {
    this.paymentStatusEntered.next(state);
  }
}
