import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {InstagramService} from 'src/app/services/instagram/instagram.service';
import {InstagramUserData} from 'src/app/types/instagram-user-data';
import {Plan} from 'src/app/types/plan';
import {faArrowLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import {PlansService} from '../../services/pricing-plans/pricing-plans.service';
import {TranslateService} from '@ngx-translate/core';
import {PasswordErrorStateMatcher, UtilitiesService} from '../../services/utilities/utilities.service';
import {Client} from '../../types/client';
import {AuthService} from '../../services/auth/auth.service';
import {ClientPaymentRequest} from '../../types/client-payment-request';
import {CoinTypeEnum} from '../../enums/coinType.enum';
import {ProductTypeEnum} from '../../enums/productType.enum';
import {LanguageTypeEnum} from '../../enums/languageType.enum';
import {PaymentTypeEnum} from '../../enums/paymentType.enum';
import {PaymentService} from '../../services/payment/payment.service';
import {UserManagementService} from '../../services/user-management/user-management.service';
import {Router} from '@angular/router';
import {AnalyticsService} from '../../services/analytics/analytics.service';
import {ProductAnalyticsEnum} from '../../enums/analytics/product.analytics.enum';
import {PlanTypeEnum} from '../../enums/planType.enum';
import {ClientRequest} from '../../types/client-request';
import {ThemeConfigService} from '../../services/theme-config/theme-config.service';
import {SmmCategoryEnum} from "../../enums/smm-category.enum";

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit {

  public showSpinner: boolean = false;
  public passwordMinLength = 6;
  public paymentRouting;
  public matcher = new PasswordErrorStateMatcher();
  public signupForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    passwordGroup: this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]],
      confirmPassword: ['']
    }, {validator: this.utilsService.validatePasswords}),
    package: new FormControl('', [Validators.required]),
  });

  public selectedInstagramUser: InstagramUserData = {
    id: '',
    profilePicUrl: '',
    userName: '',
  };
  public errorMessage;
  public imgSrc: SafeUrl = '';
  public stepTitle = '';
  public stepSubTitle = '';
  public showUsersStep = true;
  public showFormStep = false;
  public backIcon = faArrowLeft;
  public closeIcon = faTimes;
  public planText: string;
  // public selectedPlanText: string = '';
  public pricingPlans: Plan[];
  public formPricingPlans;

  constructor(public dialogRef: MatDialogRef<SignupModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { plan: Plan },
              private pricingPlanService: PlansService,
              private instagramService: InstagramService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private utilService: UtilitiesService,
              private analyticsService: AnalyticsService,
              private themeService: ThemeConfigService,
              private userManagementService: UserManagementService,
              private paymentService: PaymentService,
              private utilsService: UtilitiesService,
              private domSanitizer: DomSanitizer,
              private translateService: TranslateService) {
    this.translateService.get('MODALS.SIGNUP.SELECT_USER_STEP.TITLE').subscribe(translation => {
      this.stepTitle = translation;
    });

  }


  ngOnInit(): void {
    const password = this.generateRandomPassowrd();
    this.signupForm.get('passwordGroup').get('password').setValue(password);
    this.signupForm.get('passwordGroup').get('confirmPassword').setValue(password);
    this.pricingPlanService.getAllPlans().then(result => {
      this.pricingPlans = result;
      this.formPricingPlans = [
        {
          planType: 'Weekly',
          plans: this.pricingPlanService.getWeeklyPricingPlansText(this.pricingPlans)
        },
        {
          planType: 'Monthly',
          plans: this.pricingPlanService.getMonthlyPricingPlansText(this.pricingPlans)
        },
        {
          planType: 'Yearly',
          plans: this.pricingPlanService.getYearlyPricingPlansText(this.pricingPlans)
        }
      ];
      if (this.data) {
        const InitialPlanText = this.pricingPlanService.getPricingPlanTextById(this.pricingPlans, this.data.plan.id);
        if (InitialPlanText) {
          this.planText = InitialPlanText.text;
          this.signupForm.get('package')?.setValue(InitialPlanText.id);
        }
      }
      this.authService.getCurrentUser().then((authUser) => {
        if (authUser) {
          location.href = this.themeService.themeConfig.dashboardUrl;
        }
      });
    }, error => {
      // TODO: handle error
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  async emailClicked() {
    const plan: Plan = await this.getPlan();
    this.analyticsService.emailFieldClick(plan?.price, this.pricingPlanService.getPackageName(plan),
      ProductAnalyticsEnum.likesSubscription, plan?.planType, this.selectedInstagramUser?.userName);
  }

  async passwordClicked() {
    const plan: Plan = await this.getPlan();
    this.analyticsService.passwordFieldClick(plan?.price, this.pricingPlanService.getPackageName(plan),
      ProductAnalyticsEnum.likesSubscription, plan?.planType, this.selectedInstagramUser?.userName);
  }

  async userSelected(selectedUser: InstagramUserData) {
    this.selectedInstagramUser = selectedUser;
    const plan: Plan = await this.getPlan();
    this.analyticsService.selectInstagramUser(plan?.price, ProductAnalyticsEnum.likesSubscription,
      selectedUser.userName, plan?.planType, this.pricingPlanService.getPackageName(plan));
    this.instagramService.getImageByUrl(this.selectedInstagramUser.profilePicUrl).then((response) => {
      this.imgSrc = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${response}`);
    });
    this.formStep();
  }

  formStep() {
    this.showUsersStep = false;
    this.showFormStep = true;
    this.translateService.get('MODALS.SIGNUP.FORM_STEP.TITLE').subscribe(translation => {
      this.stepTitle = translation;
    });
    this.translateService.get('MODALS.SIGNUP.FORM_STEP.SUB_TITLE').subscribe(translation => {
      this.stepSubTitle = translation;
    });
  }

  chooseUserStep() {
    this.showUsersStep = true;
    this.showFormStep = false;

    this.translateService.get('MODALS.SIGNUP.SELECT_USER_STEP.TITLE').subscribe(translation => {
      this.stepTitle = translation;
    });

    this.stepSubTitle = '';
    this.imgSrc = '';
  }

  onSubmit() {
    console.log(this.signupForm);

  }

  getEmailErrorMessage(): string {

    let error = '';

    if (this.signupForm.get('email')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    if (this.signupForm.get('email')?.hasError) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.INVALID', {field: 'email'}).subscribe(translation => {
        error = translation;
      });
    } else {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.UNKNOWN', {field: 'email'}).subscribe(translation => {
        error = translation;
      });
    }

    return error;
  }

  getPasswordErrorMessage(): string {
    let error = '';

    if (this.signupForm.get('passwordGroup').get('password')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    if (this.signupForm.get('passwordGroup').get('password')?.hasError) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.PASSWORD_PATTERN', {minLength: this.passwordMinLength}).subscribe(translation => {
        error = translation;
      });
    } else {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.UNKNOWN', {field: 'password'}).subscribe(translation => {
        error = translation;
      });
    }

    return error;
  }

  getConfirmPasswordErrorMessage(): string {

    let error = '';

    if (this.signupForm.get('passwordGroup').get('confirmPassword')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    this.translateService.get('COMMON.FORM.FORM_ERRORS.MATCHING_PASSWORDS').subscribe(translation => {
      error = translation;
    });

    return error;
  }

  getPackageErrorMessage(): string {

    let error = '';

    if (this.signupForm.get('package')?.hasError('required')) {
      this.translateService.get('COMMON.FORM.FORM_ERRORS.PACKAGE_REQUIRED').subscribe(translation => {
        error = translation;
      });
    }

    return error;
  }

  async signup() {
    this.showSpinner = true;
    this.errorMessage = '';
    if (this.signupForm.valid) {
      const value = this.signupForm.value;
      const client: Client = {
        email: value.email,
        packageId: value.package,
        brandId: this.themeService.themeConfig.brandId,
        social: {instagram: {id: this.selectedInstagramUser.id, userName: this.selectedInstagramUser.userName}}
      };
      const plan: Plan = await this.getPlan();
      const analyticsType = plan.smmCategory === SmmCategoryEnum.likes ? ProductAnalyticsEnum.likesSubscription : ProductAnalyticsEnum.verifiedComments;
      try {
        await this.authService.providerSignup(client, value.passwordGroup.password);
        const providerUser = await this.authService.providerLogin(client.email, value.passwordGroup.password);
        const InitialPlanText = this.pricingPlanService.getPricingPlanTextById(this.pricingPlans, value.package);
        if (InitialPlanText) {
          this.planText = InitialPlanText.text;
        }
        this.analyticsService.signupSuccsess(plan?.price, analyticsType,
          this.selectedInstagramUser.userName, plan?.planType, this.pricingPlanService.getPackageName(plan));
        await this.authService.sendInfoForEmail(client.email, value.passwordGroup.password);
        await this.makePayment(providerUser.getUsername(), value.package, value.email);
      } catch (error) {
        this.analyticsService.signupFail(plan?.price, analyticsType,
          this.selectedInstagramUser.userName, plan?.planType, this.pricingPlanService.getPackageName(plan), error.message);
        this.errorMessage = error.message;

        this.showSpinner = false;
      }
    }

    this.showSpinner = false;
  }

  public async makePayment(id: string, planId: string, email: string) {
    this.paymentRouting = true;
    const plan: Plan = await this.getPlan();
    const queryParams = this.utilService.getStore('query');
    const paymentRequest: ClientPaymentRequest =
      {
        uid: id,
        socialUserId: this.selectedInstagramUser.userName,
        email,
        coinID: CoinTypeEnum.USD,
        products: [{
          id: '1',
          type: plan.smmCategory === SmmCategoryEnum.likes ? ProductTypeEnum.likes : ProductTypeEnum.verifiedCommentPlan,
          freemium: this.data?.plan?.freemium,
          name: this.planText,
          paymentType: PaymentTypeEnum.recurring,
          campaign: {
            vendorType: queryParams.MPC_1,
            transactionId: queryParams.MPC_6,
            queryString: this.utilService.getStore('queryString'),
            queryParams,
          },
          planId
        }],
        language: LanguageTypeEnum.english,
        isAnonymous: false
      };
    const results = await this.paymentService.charge(paymentRequest);
    if (results.success) {
      const analyticsType = plan.smmCategory === SmmCategoryEnum.likes ? ProductAnalyticsEnum.likesSubscription : ProductAnalyticsEnum.verifiedComments;
      const paymentData = {
        url: results.value,
        conversionValue: plan.price,
        product: analyticsType,
        packageName: this.pricingPlanService.getPackageName(plan),
        renewable: plan.planType,
        freemium: this.data?.plan?.freemium,
        userName: this.selectedInstagramUser.userName
      };
      this.paymentService.storePayment(paymentData);
      this.router.navigate(['payment/process']).then(() => {
        this.closeModal();
      });
    } else {
      this.paymentRouting = false;
      this.paymentService.deletePayment();
      // TODO: error handling
    }
  }

  private async getPlan() {
    const planId = (this.signupForm.value ? this.signupForm.value.package : '') || this.data?.plan?.id;
    return planId ? await this.pricingPlanService.getPricingPlanById(planId) : null;
  }

  generateRandomPassowrd() {
    return Math.random().toString(36).slice(-8);
  }
}
