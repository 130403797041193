import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { InstagramService } from 'src/app/services/instagram/instagram.service';
import { InstagramPost } from 'src/app/types/instagram-post';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-instagram-post',
  templateUrl: './instagram-post.component.html',
  styleUrls: ['./instagram-post.component.scss']
})
export class InstagramPostComponent implements OnInit {

  @Input() postData: InstagramPost = {
    imgUrl: '',
    numberOfLikes: 0,
    postId: '',
    shortcode: '',
    selected: false,
    origin: ''
  };

  public imgSrc: SafeUrl = '';
  public heartIcon = faHeart;
  public numberOfLikes: string;

  constructor(private instagramService: InstagramService,
              private domSanitizer: DomSanitizer,
              private utilsService: UtilitiesService) {

  }

  ngOnInit(): void {
    this.instagramService.getImageByUrl(this.postData.imgUrl).then((response) => {
      this.imgSrc = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${response}`);
      this.numberOfLikes = this.utilsService.numberWithCommas(this.postData.numberOfLikes);
    });
  }


}
